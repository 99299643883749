import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Offcanvas from "react-bootstrap/Offcanvas";
import Card from "react-bootstrap/Card";
import ModalSave from "../../../common/ModalSave";
import Validation from "../../../common/FormValidation";
import { CancelButton, SaveButton } from "../../../common/Button";
// import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import { Col, Container, Row, Form, InputGroup } from "react-bootstrap";
import Select from "react-select";
import { VehicleCategory,AllCity } from "../../../../utils/apis/masters/masters";
library.add(fas);
const EditOffCanvance = (props) => {
  const { postData, getData, IMG_URL } = useContext(Context);
  const [errors, setErrors] = useState();
  const id = props.show;
  const [formData, setFormData] = useState({
    name: "",
    image: null,
  });

  const [imagePreview, setImagePreview] = useState(null);
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });
  // const handleChange = (e) => {
  //   if (e.target.type === "file") {
  //     const selectedImage = e.target.files[0];

  //     // Check if a file is selected
  //     if (selectedImage) {
  //       // Generate image preview
  //       const reader = new FileReader();
  //       reader.onloadend = () => {
  //         setImagePreview(reader.result);
  //       };
  //       reader.readAsDataURL(selectedImage);

  //       // Update formData with the selected image
  //       setFormData({ ...formData, image: selectedImage });
  //     } else {
  //       // User canceled image selection, clear the image preview and formData
  //       setImagePreview(null);
  //       setFormData({ ...formData, image: null });
  //     }
  //   } else {
  //     // Handle text input
  //     setFormData({ ...formData, [e.target.name]: e.target.value });
  //   }
  // };

  const validateForm = () => {
    let errors = {};
    const regexNumeric = /^[0-9]+$/;
    const regexAlfaNumric = /^[A-Za-z0-9]+(\s[A-Za-z0-9]+)*$/;
    const regexAlfa = /^[A-Za-z]+(\s[A-Za-z]+)*$/;

    if (!formData.city_id) {
      errors.city_id = "city_id is required";
    } 
    // else if (formData.name.length > 81) {
    //   errors.name = "Title Must be 80 characters Maximum";
    // }
    if (!formData.vehicle_category_id) {
      errors.vehicle_category_id = "vehicle_category_id is required";
    }

    if (!formData.fare) {
      errors.fare = "fare is required";
    }
    return errors;
  };

  const GetEditData = async () => {
    const response = await getData(`/masters/vehicle-fare/${id}`);
    setFormData(response?.data);
  };
  useEffect(() => {
    GetEditData();
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const formDataToSend = new FormData();
        formDataToSend.append("fare", formData.fare);
        formDataToSend.append("city_id", formData.city_id?.value);
        formDataToSend.append("vehicle_category_id", formData.vehicle_category_id?.value);
        const response = await postData(
          `/masters/vehicle-fare/${id}`,
          formDataToSend
        );
        if (response.success) {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        } else {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };
  const [vehicle, setvehicle] = useState([]);
  const [city, setCity] = useState([]);
  const GetAllVehicle = async () => {
    const response = await VehicleCategory();

    const option = [];
    if (response.success) {
      response?.data?.map((data) => {
        option.push({
          value: data.id,
          name: "vehicle_category_id",
          label: data.name,
        });
      });
    }
    setvehicle(option);
  };
  const errorStyle = {
    color: "red",
    marginLeft: "5px",
    // fontSize: "11px",
  };

  const GetAllCity = async () => {
    const response = await AllCity();

    const option = [];
    if (response.success) {
      response?.data?.map((data) => {
        option.push({
          value: data.id,
          name: "city_id",
          label: data.name,
        });
      });
    }
    setCity(option);
  };

  useEffect(() => {
    GetAllVehicle();
    GetAllCity();
  }, []);

  const handleChange = (e) => {
    if (e?.name == "city_id") {
      setFormData({ ...formData, [e.name]: e });
    } else if (e?.name == "vehicle_category_id") {
      setFormData({ ...formData, [e.name]: e });
    } else
     {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };
  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton>
          {/* <Offcanvas.Title>Edit User Help Type</Offcanvas.Title> */}
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Container fluid>
            <Row>
              {/* Right side - Form */}
              <Col md={12}>
                <Card className="shadow-lg p-3 mb-5 bg-white rounded">
                  <Card.Body>
                    <Card.Title>Edit Vehicle Fare</Card.Title>
                    <hr />
                    <Container>
                      <Row>
                        {/* Editing fields */}
                        <Col md={8}>
                          <Row>
                            {/* <Col md={12}>
                              <div className="main-form-section mt-5">
                                <Row className="justify-content-center">
                                  <Form.Label column sm={3}>
                                    Title
                                  </Form.Label>
                                  <Col sm={6}>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="text"
                                          name="name"
                                          value={formData.name}
                                          onChange={handleChange}
                                          maxLength={50}
                                          placeholder="Name "
                                          id="inputEmail3"
                                          className="input-mandatory"
                                        />
                                      </InputGroup>
                                      {errors?.name && (
                                        <span style={errorStyle}>
                                          {errors?.name}
                                        </span>
                                      )}
                                    </Form.Group>
                                  </Col>
                                </Row>
                              </div>
                            </Col> */}
                             <Col md={6}>
                                <div className="main-form-section mt-5">
                                  <Row className="row justify-content-center mb-2">
                                    <Form.Label column sm={3}>
                                      Vehicle category{" "}
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Select
                                        name="vehicle_category_id"
                                        className="input-mandatory"
                                        onChange={handleChange}
                                        placeholder="Select vehicle"
                                        value={formData.vehicle_category_id}
                                        options={vehicle}
                                      />
                                      {errors?.vehicle_category_id && (
                                              <span style={errorStyle}>
                                                {errors?.vehicle_category_id}
                                              </span>
                                            )}
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="main-form-section mt-5">
                                  <Row className="row justify-content-center mb-2">
                                    <Form.Label column sm={3}>
                                      City{" "}
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Select
                                        name="city_id"
                                        className="input-mandatory"
                                        onChange={handleChange}
                                        placeholder="Select City"
                                        value={formData.city_id}
                                        options={city}
                                      />
                                      {errors?.city_id && (
                                              <span style={errorStyle}>
                                                {errors?.city_id}
                                              </span>
                                            )}
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                            
                          </Row>
                          <Col md={12}>
                                    <div className="main-form-section mt-5">
                                      <Row className="justify-content-center">
                                        <Form.Label column sm={3}>
                                          Fare
                                        </Form.Label>
                                        <Col sm={6}>
                                          <Form.Group>
                                            <InputGroup>
                                              <Form.Control
                                                type="text"
                                                name="fare"
                                                value={formData.fare}
                                                onChange={handleChange}
                                                maxLength={50}
                                                placeholder="fare "
                                                id="inputEmail3"
                                                className="input-mandatory"
                                              />
                                            </InputGroup>
                                            {errors?.fare && (
                                              <span style={errorStyle}>
                                                {errors?.fare}
                                              </span>
                                            )}
                                          </Form.Group>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col>
                          {/* <Row>
                            <Col md={12}>
                              <div className="main-form-section mt-5">
                                <Row className="justify-content-center">
                                  <Form.Label column sm={3}>
                                  Number
                                  </Form.Label>
                                  <Col sm={6}>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="text"
                                          name="number"
                                          value={formData.number}
                                          onChange={handleChange}
                                          // maxLength={50}
                                          placeholder="number"
                                          id="inputEmail3"
                                          className="input-mandatory"
                                        />
                                      </InputGroup>
                                      {errors?.number && (
                                        <span style={errorStyle}>
                                          {errors?.number}
                                        </span>
                                      )}
                                    </Form.Group>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                          </Row> */}

                        
                          <Row className="mt-5 pb-3">
                            <div className="d-flex justify-content-center">
                              <Link>
                                <CancelButton
                                  name={"cancel"}
                                  handleClose={props.handleClose}
                                />
                              </Link>
                              <SaveButton
                                name={"save"}
                                handleSubmit={handleSubmit}
                              />
                            </div>
                          </Row>
                        </Col>
                      </Row>
                    </Container>
                  </Card.Body>
                </Card>
              </Col>
              
            </Row>
          </Container>
        </Offcanvas.Body>
      </Offcanvas>
      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};
export default EditOffCanvance;
