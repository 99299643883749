import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Offcanvas from "react-bootstrap/Offcanvas";

import Card from "react-bootstrap/Card";
import ModalSave from "../../../common/ModalSave";
import Validation from "../../../common/FormValidation";
import { CancelButton, SaveButton } from "../../../common/Button";
import { Languages } from "../../../../utils/apis/masters/masters";
import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";

library.add(fas);

const AddOffCanvance = (props) => {
  const { postData, minLength, maxLength } = useContext(Context);

  const [formData, setFormData] = useState({
    count: "",
  });
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  // const handleChange = (e) => {
  //   setFormData({ ...formData, [e.target.name]: e.target.value });
  // };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   const validationErrors = await Validation.FormValidation({
  //     classname: ".countryclass",
  //   });
  //   console.log(validationErrors);

  //   if (validationErrors) {
  //     try {
  //       const response = await postData("/masters/country", formData);
  //       if (response.success) {
  //         setShowModal({ code: response.code, message: response.message });
  //         setTimeout(() => {
  //           setShowModal(0);
  //           props.handleClose();
  //         }, 1000);
  //       } else {
  //         setShowModal({ code: response.code, message: response.message });
  //         setTimeout(() => {
  //           setShowModal(0);
  //           props.handleClose();
  //         }, 1000);
  //       }
  //     } catch (error) {
  //       console.error("Error submitting form:", error);
  //     }
  //   }
  // };

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let errors = {};

    countLang.forEach((field) => {
      if (!formData[field.id]) {
        errors[field.id] = "rider partner name is required";
      } else {
        const trimmedName = formData[field.id].trim();

        if (trimmedName.length < minLength || trimmedName.length > maxLength) {
          errors[
            field.id
          ] = `rider partner name must be between ${minLength} and ${maxLength} characters`;
        }
      }
    });

    return errors;
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const response = await postData("/masters/rider-help-type", formData);
        if (response.success) {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        } else {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const [countLang, setCountLang] = useState([]);

  const getLanguges = async () => {
    const response = await Languages();
    setCountLang(response.data);
    setFormData({ ...formData, count: response?.data });
  };

  console.log(countLang);

  useEffect(() => {
    getLanguges();
  }, []);

  const errorStyle = {
    color: "red",
    marginLeft: "5px",
  };

  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton>
          {/* <Offcanvas.Title>Add Country</Offcanvas.Title> */}
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="shadow-lg p-3 mb-5 bg-white rounded ">
            <Card.Body>
              <Card.Title>Add Rider Help Type</Card.Title>
              <hr />
              <Container>
                <Row>
                  <Col md={12}>
                    <Container>
                      <Row className="">
                        <Col xxl={12} xl={12} md={10}>
                          <Form
                            onSubmit={handleSubmit}
                            role="form"
                            className="countryclass"
                          >
                            <Row>
                              <Col
                                lg={8}
                                md={10}
                                className=" mx-auto Add-content"
                              >
                                <Row>
                                  {countLang?.map((data, index) => {
                                    return (
                                      <Col md={12}>
                                        <div className="main-form-section mt-5">
                                          <Row className="justify-content-center">
                                            <Form.Label column sm={3}>
                                            Rider Help Type Name {data?.name}
                                            </Form.Label>
                                            <Col sm={6}>
                                              <Form.Group>
                                                <InputGroup>
                                                  <Form.Control
                                                    type="text"
                                                    name={data.id}
                                                    placeholder={`Enter country name in ${data?.name}`}
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    id="inputEmail3"
                                                    className="input-mandatory"
                                                  />
                                                </InputGroup>
                                                {errors[data.id] && (
                                                  <span style={errorStyle}>
                                                    {errors[data.id] +
                                                      " in " +
                                                      data.name}
                                                  </span>
                                                )}
                                                {/* <span className="error-message"></span> */}
                                              </Form.Group>
                                            </Col>
                                          </Row>
                                        </div>
                                      </Col>
                                    );
                                  })}
                                </Row>

                                <Row className="mt-5 pb-3">
                                  <div className="d-flex justify-content-center">
                                    <Link>
                                      <CancelButton
                                        name={"cancel"}
                                        handleClose={props.handleClose}
                                      />
                                    </Link>
                                    <SaveButton
                                      name={"save"}
                                      handleSubmit={handleSubmit}
                                    />
                                  </div>
                                </Row>
                              </Col>
                            </Row>
                          </Form>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Offcanvas.Body>
      </Offcanvas>
      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
