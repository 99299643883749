import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../../utils/context";

import Offcanvas from "react-bootstrap/Offcanvas";

import Card from "react-bootstrap/Card";
import Select from "react-select";
import ModalSave from "../../../common/ModalSave";
import Validation from "../../../common/FormValidation";
import { CancelButton, SaveButton } from "../../../common/Button";

import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import { City, Country, State, Pincode } from "../../../../utils/apis/masters/masters";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
const AddOffCanvance = (props) => {
  const { postData, getData } = useContext(Context);
  const [errors, setErrors] = useState();
  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [pincode, setPincode] = useState([]);
  const [zone, setZone] = useState([]);
  const [adharcardPreview, setadharcardPreview] = useState(null);
  const [profilephotoPreview, setprofilephotoPreview] = useState(null);
  const [drivinglicensePreview, setdrivinglicensePreview] = useState(null);
  const [registrationcertificationPreview, setregistrationcertificationPreview] = useState(null);
  const [formData, setFormData] = useState({
    country_id: "",
    state_id: "",
    city_id: "",
    pincode_id: "",
    f_name: "",
    contact_no: "",
    email: "",
    password: '',
    c_password: '',
    adhar_card: null,
    profile_photo: null,
    driving_license: null,
    registration_certification: null,
  });

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const handleChange = (e) => {
    if (e.name === "country_id") {
      setFormData({
        ...formData,
        [e.name]: e,
        state_id: "",
        city_id: "",
        pincode_id: "",
      });
      getSelectedState(e.value);
    } else if (e.name === "state_id") {
      setFormData({
        ...formData,
        [e.name]: e,
        city_id: "",
        pincode_id: "",
      });
      getSelectedCity(e.value);
    } else if (e.name === "city_id") {
      setFormData({
        ...formData,
        [e.name]: e,
        pincode_id: "",
      });
      getSelectedPincode(e.value);
    } else if (e.name === "pincode_id") {
      setFormData({
        ...formData,
        [e.name]: e,
      });
    } else if (e.target.name === "adhar_card") {
      setFormData({ ...formData, [e.target.name]: e.target.files[0] });
      setadharcardPreview(URL.createObjectURL(e.target.files[0]));
    } else if (e.target.name === "profile_photo") {
      setFormData({ ...formData, [e.target.name]: e.target.files[0] });
      setprofilephotoPreview(URL.createObjectURL(e.target.files[0]));
    } else if (e.target.name === "driving_license") {
      setFormData({ ...formData, [e.target.name]: e.target.files[0] });
      setdrivinglicensePreview(URL.createObjectURL(e.target.files[0]));
    } else if (e.target.name === "registration_certification") {
      setFormData({ ...formData, [e.target.name]: e.target.files[0] });
      setregistrationcertificationPreview(URL.createObjectURL(e.target.files[0]));
    } else if (e.target.name === "contact_no") {

      const numericValue = e.target.value.replace(/\D/g, '');
      if (numericValue.length <= 10) {
        setFormData({ ...formData, [e.target.name]: numericValue });
      }
    }
    else {
      setFormData({ ...formData, [e.target.name]: e.target.value });

    }
  };



  const validateForm = () => {
    let errors = {};
    const regexNumeric = /^[0-9]+$/;
    const regexContactNo = /^[0-9]{10}$/;

    // Name validation
    if (!formData.f_name) {
      errors.f_name = "Name is required";
    }

    // Contact No validation
    if (!formData.contact_no) {
      errors.contact_no = "Contact number is required";
    } else if (!regexContactNo.test(formData.contact_no)) {
      errors.contact_no = "Contact number must be 10 digits long and contain only numbers";
    }
    if (!formData.country_id) {
      errors.country_id = "Country is required";
    }
    if (!formData.state_id) {
      errors.state_id = "State is required";
    }
    if (!formData.city_id) {
      errors.city_id = "City is required";
    }
    if (!formData.pincode_id) {
      errors.pincode_id = "pincode is required";
    }

    if (!formData.password) {
      errors.password = "password is required";
    } else if (!formData.c_password) {
      errors.c_password = "conform password is required";
    } else
      if (formData.password && formData.c_password && formData.password !== formData.c_password) {
        errors.c_password = 'Passwords do not match to conform password';
      }
    // Image validations
    if (!formData.adhar_card) {
      errors.adhar_card = "Adhar card image is required";
    }
    if (!formData.profile_photo) {
      errors.profile_photo = "Profile photo is required";
    }
    if (!formData.driving_license) {
      errors.driving_license = "Driving license image is required";
    }
    if (!formData.registration_certification) {
      errors.registration_certification = "Registration certification image is required";
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Form field validations
    const validationErrors = validateForm();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      const formDataToSend = new FormData();
      Object.keys(formData).forEach((key) => {
        if (formData[key] instanceof File || formData[key] === null) {
          formDataToSend.append(key, formData[key]);
        } else {
          formDataToSend.append(key, formData[key]?.value || formData[key]);
        }
      });

      try {
        const response = await postData("/customers/riders", formDataToSend, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (response.success) {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        } else {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };




  const errorStyle = {
    color: "red",
    marginLeft: "5px",
  };

  const successStyle = {
    color: "green",
    marginLeft: "5px",
  };

  async function getSelectedState(id) {
    const response = await State(id);

    if (response.success) {
      setState(response.data);
    }
  }

  async function getSelectedCity(id) {
    const response = await City(id);

    if (response.success) {
      setCity(response.data);
    }
  }

  async function getSelectedPincode(id) {
    const response = await Pincode(id);

    if (response.success) {
      setPincode(response.data);
    }
  }

  const GetAllCountry = async () => {
    const response = await Country();

    const option = [];
    if (response.success) {
      response?.data?.map((data) => {
        option.push({
          value: data.id,
          name: "country_id",
          label: data.name,
        });
      });
    }
    setCountry(option);
  };

  const GetAllZone = async () => {
    const response = await getData("/masters/zone");
    const option = [];
    if (response.success) {
      response?.data?.data?.map((data) => {
        option.push({
          value: data.id,
          name: "zone_id",
          label: data.name,
        });
      });
    }
    setZone(option);
  };

  useEffect(() => {
    GetAllCountry();
    GetAllZone();
  }, []);

  const [showPassword, setShowPassword] = useState(false);
  const [showConformPassword, setShowConformPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
    
  };
  const toggleConformPasswordVisibility = () => {
 
    setShowConformPassword(!showConformPassword)
  };

  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="shadow-lg p-3 mb-5 bg-white rounded">
            <Card.Body>
              <Card.Title>Add Rider</Card.Title>
              <hr />
              <Container>
                <Row>
                  <Col md={12}>
                    <Container>
                      <Row className="">
                        <Col xxl={12} xl={12} md={10}>
                          <Form
                            onSubmit={handleSubmit}
                            role="form"
                            className="pincodeclass"
                          >
                            <Row>
                              <Col md={6}>
                                <div className="main-form-section mt-5">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      Name
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="text"
                                            name="f_name"
                                            value={formData?.f_name}
                                            onChange={handleChange}
                                            placeholder="Name "
                                            id="inputEmail3"

                                            className="input-mandatory"
                                          />
                                        </InputGroup>
                                        {errors?.f_name && (
                                          <span style={errorStyle}>
                                            {errors?.f_name}
                                          </span>
                                        )}
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="main-form-section mt-5">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      Contact No
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="text"
                                            name="contact_no"
                                            value={formData?.contact_no}
                                            onChange={handleChange}
                                            placeholder="contact no "
                                            id="inputEmail3"

                                            className="input-mandatory"
                                          />
                                        </InputGroup>
                                        {errors?.contact_no && (
                                          <span style={errorStyle}>
                                            {errors?.contact_no}
                                          </span>
                                        )}
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>

                              {/* <Col md={6}>
                                <div className="main-form-section mt-5">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                    Email
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="text"
                                            name="email"
                                            value={formData?.email}
                                            onChange={handleChange}
                                            placeholder="email"
                                            id="inputEmail3"
                                            
                                            className="input-mandatory"
                                          />
                                        </InputGroup>
                                        <span className="error-message"></span>
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>
                              </Col> */}

                              <Col md={6}>
                                <div className="main-form-section mt-5">
                                  <Row className="row justify-content-center mb-2">
                                    <Form.Label column sm={3}>
                                      Select Country{" "}
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Select
                                        name="country_id"
                                        className="input-mandatory"
                                        onChange={(selectedOption) =>
                                          handleChange(selectedOption)
                                        }
                                        value={formData.country_id}
                                        options={country}
                                      />
                                      {errors?.country_id && (
                                        <span style={errorStyle}>
                                          {errors?.country_id}
                                        </span>
                                      )}


                                    </Col>
                                  </Row>
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="main-form-section mt-5">
                                  <Row className="row justify-content-center mb-2">
                                    <Form.Label column sm={3}>
                                      Select State{" "}
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Select
                                        name="state_id"
                                        className="input-mandatory"
                                        onChange={handleChange}
                                        value={formData.state_id}
                                        options={state.map((stateOption) => ({
                                          value: stateOption.id,
                                          name: "state_id",
                                          label: stateOption.name,
                                        }))}
                                      />

                                      {errors?.state_id && (
                                        <span style={errorStyle}>
                                          {errors?.state_id}
                                        </span>
                                      )}
                                    </Col>
                                  </Row>
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="main-form-section mt-5">
                                  <Row className="row justify-content-center mb-2">
                                    <Form.Label column sm={3}>
                                      Select City{" "}
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Select
                                        name="city_id"
                                        className="input-mandatory"
                                        onChange={handleChange}
                                        value={formData.city_id}
                                        options={city?.map((stateOption) => ({
                                          value: stateOption.id,
                                          name: "city_id",
                                          label: stateOption.name,
                                        }))}
                                      />

                                      {errors?.city_id && (
                                        <span style={errorStyle}>
                                          {errors?.city_id}
                                        </span>
                                      )}
                                    </Col>
                                  </Row>
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="main-form-section mt-5">
                                  <Row className="row justify-content-center mb-2">
                                    <Form.Label column sm={3}>
                                      Select PinCode{" "}
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Select
                                        name="pincode_id"
                                        className="input-mandatory"
                                        onChange={handleChange}
                                        value={formData.pincode_id}
                                        options={pincode?.map((stateOption) => ({
                                          value: stateOption.id,
                                          name: "pincode_id",
                                          label: stateOption.name,
                                        }))}
                                      />

                                      {errors?.pincode_id && (
                                        <span style={errorStyle}>
                                          {errors?.pincode_id}
                                        </span>
                                      )}
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="main-form-section mt-5">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      Password
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            // type="text"
                                            type={showPassword ? 'text' : 'password'}
                                            name="password"
                                            value={formData?.password}
                                            onChange={handleChange}
                                            placeholder="Password "
                                            id="inputEmail3"

                                            className="input-mandatory"
                                          />
                                          <InputGroup.Text onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }}>
                                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                                          </InputGroup.Text>
                                        </InputGroup>
                                        {errors?.password && (
                                          <span style={errorStyle}>
                                            {errors?.password}
                                          </span>
                                        )}
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="main-form-section mt-5">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      Conform Password
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            // type="text"
                                            type={showConformPassword ? 'text' : 'password'}
                                            name="c_password"
                                            value={formData?.c_password}
                                            onChange={handleChange}
                                            placeholder="Conform Password "
                                            id="inputEmail3"

                                            className="input-mandatory"
                                          />
                                          <InputGroup.Text onClick={toggleConformPasswordVisibility} style={{ cursor: 'pointer' }}>
                                            {showConformPassword ? <FaEyeSlash /> : <FaEye />}
                                          </InputGroup.Text>
                                        </InputGroup>
                                        {errors?.c_password && (
                                          <span style={errorStyle}>
                                            {errors?.c_password}
                                          </span>
                                        )}
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>


                              <Col md={6}>
                                <div className="main-form-section mt-5">
                                  <Row className="row justify-content-center mb-2">
                                    <Form.Label column sm={3}>
                                      Adhar Card Image
                                    </Form.Label>

                                    <Col sm={6}>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="file"
                                            name="adhar_card"
                                            accept="image/*"
                                            onChange={handleChange}
                                          />
                                        </InputGroup>
                                        {errors?.adhar_card && (
                                          <span style={errorStyle}>
                                            {errors?.adhar_card}
                                          </span>
                                        )}
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="main-form-section mt-5">
                                  <Row className="row justify-content-center mb-2">
                                    <Form.Label column sm={3}>
                                      Image Preview
                                    </Form.Label>
                                    <Col sm={6}>
                                      {adharcardPreview ? (
                                        <img
                                          src={adharcardPreview}
                                          alt="Image Preview"
                                          className="table-image"
                                          style={{
                                            height: "100px",
                                            width: "100px",
                                            // margin: "2px",
                                            // marginLeft: "300px",
                                            // backgroundSize: "fixed",
                                          }}
                                        />
                                      ) : (
                                        <p style={{ marginLeft: "100px" }}>
                                          No image selected
                                        </p>
                                      )}
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="main-form-section mt-5">
                                  <Row className="row justify-content-center mb-2">
                                    <Form.Label column sm={3}>
                                      Registration Certification Image
                                    </Form.Label>

                                    <Col sm={6}>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="file"
                                            name="registration_certification"
                                            accept="image/*"
                                            onChange={handleChange}
                                          />
                                        </InputGroup>
                                        {errors?.registration_certification && (
                                          <span style={errorStyle}>
                                            {errors?.registration_certification}
                                          </span>
                                        )}
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="main-form-section mt-5">
                                  <Row className="row justify-content-center mb-2">
                                    <Form.Label column sm={3}>
                                      Image Preview
                                    </Form.Label>
                                    <Col sm={6}>
                                      {registrationcertificationPreview ? (
                                        <img
                                          src={registrationcertificationPreview}
                                          alt="Image Preview"
                                          className="table-image"
                                          style={{
                                            height: "100px",
                                            width: "100px",
                                            // margin: "2px",
                                            // marginLeft: "300px",
                                            // backgroundSize: "fixed",
                                          }}
                                        />
                                      ) : (
                                        <p style={{ marginLeft: "100px" }}>
                                          No image selected
                                        </p>
                                      )}
                                    </Col>
                                  </Row>
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="main-form-section mt-5">
                                  <Row className="row justify-content-center mb-2">
                                    <Form.Label column sm={3}>
                                      Driving License Image
                                    </Form.Label>

                                    <Col sm={6}>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="file"
                                            name="driving_license"
                                            accept="image/*"
                                            onChange={handleChange}
                                          />
                                        </InputGroup>
                                        {errors?.driving_license && (
                                          <span style={errorStyle}>
                                            {errors?.driving_license}
                                          </span>
                                        )}
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="main-form-section mt-5">
                                  <Row className="row justify-content-center mb-2">
                                    <Form.Label column sm={3}>
                                      Image Preview
                                    </Form.Label>
                                    <Col sm={6}>
                                      {drivinglicensePreview ? (
                                        <img
                                          src={drivinglicensePreview}
                                          alt="Image Preview"
                                          className="table-image"
                                          style={{
                                            height: "100px",
                                            width: "100px",
                                            // margin: "2px",
                                            // marginLeft: "300px",
                                            // backgroundSize: "fixed",
                                          }}
                                        />
                                      ) : (
                                        <p style={{ marginLeft: "100px" }}>
                                          No image selected
                                        </p>
                                      )}
                                    </Col>
                                  </Row>
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="main-form-section mt-5">
                                  <Row className="row justify-content-center mb-2">
                                    <Form.Label column sm={3}>
                                      Profile Photo
                                    </Form.Label>

                                    <Col sm={6}>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="file"
                                            name="profile_photo"
                                            accept="image/*"
                                            onChange={handleChange}
                                          />
                                        </InputGroup>
                                        {errors?.profile_photo && (
                                          <span style={errorStyle}>
                                            {errors?.profile_photo}
                                          </span>
                                        )}
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="main-form-section mt-5">
                                  <Row className="row justify-content-center mb-2">
                                    <Form.Label column sm={3}>
                                      Image Preview
                                    </Form.Label>
                                    <Col sm={6}>
                                      {profilephotoPreview ? (
                                        <img
                                          src={profilephotoPreview}
                                          alt="Image Preview"
                                          className="table-image"
                                          style={{
                                            height: "100px",
                                            width: "100px",
                                            // margin: "2px",
                                            // marginLeft: "300px",
                                            // backgroundSize: "fixed",
                                          }}
                                        />
                                      ) : (
                                        <p style={{ marginLeft: "100px" }}>
                                          No image selected
                                        </p>
                                      )}
                                    </Col>
                                  </Row>
                                </div>
                              </Col>


                              <Row className="mt-5 pb-3">
                                <div className="d-flex justify-content-center">
                                  <Link>
                                    <CancelButton
                                      name={"cancel"}
                                      handleClose={props.handleClose}
                                    />
                                  </Link>
                                  <SaveButton
                                    name={"save"}
                                    handleSubmit={handleSubmit}
                                  />
                                </div>
                              </Row>
                            </Row>
                          </Form>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Offcanvas.Body>
      </Offcanvas>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
