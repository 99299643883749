import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import plus from "../../../../Components/Admin/assets/icons/a1.png";
import colunms from "../../../../Components/Admin/assets/icons/LINES.png";
import pen from "../../../../Components/Admin/assets/icons/pen.png";
import basket from "../../../../Components/Admin/assets/icons/basket.png";
import search1 from "../../../../Components/Admin/assets/icons/search.png";
import top from "../../../../Components/Admin/assets/icons/top.png";
import Table from "react-bootstrap/Table";

import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../../Header/Header";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faRotate, fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import toast, { Toaster } from "react-hot-toast";
import Card from "react-bootstrap/Card";
import Select from "react-select";
import AddOffCanvance from "./Add";
import EditOffCanvance from "./Edit";
import ModalDelete from "../../../common/ModalDelete";
import { AddButton, EditButton, DeletButton } from "../../../common/Button";

import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import { City, Country, State } from "../../../../utils/apis/masters/masters";

library.add(fas);

const Tables = () => {
  const {
    getData,
    editStatusData,
    deleteData,
    ErrorNotify,
    isAllow,
    Per_Page_Dropdown,
  } = useContext(Context);

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setperPage] = useState(5);
  const [search, setSearch] = useState("");
  const [reset, setReset] = useState();
  const [show, setShowAdd] = useState(false);
  const [show1, setShowEdit] = useState(0);
  const [option, setOption] = useState();

  const [changeStatus, setChangeStatus] = useState();

  const getDataAll = async () => {
    const response = await getData(
      `/masters/pincode?page=${currentPage}&per_page=${perPage}&term=${search}&country=${
        selectcountry?.value || null
      }&state=${selectstate?.value || null}&city=${selectcity?.value || null}`
    );
    await setData(response);
    setCurrentPage(response?.data?.currentPage);
    setperPage(response?.data?.per_page);
    setSearch(response?.data?.search_name);
    setOption(await Per_Page_Dropdown(response?.data?.totalEntries));
  };

  useEffect(() => {
    getDataAll();
  }, [changeStatus, perPage, reset, show, show1]);

  const ChangeStatus = async (id) => {
    const response = await editStatusData(`/masters/pincode/${id}`);
    setChangeStatus(response);
  };

  // Delete module.....................................................
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [recordToDeleteId, setRecordToDeleteId] = useState(null);
  const [recordToDeleteName, setRecordToDeleteName] = useState(null);

  const showDeleteRecord = async (id, name) => {
    setShowDeleteModal(true);
    setRecordToDeleteId(id);
    setRecordToDeleteName(name);
  };

  // Modal function
  const handleClose = () => setShowAdd(false);
  const handleShow = () => setShowAdd(true);

  const handleClose1 = () => setShowEdit(0);
  const handleShow1 = (id) => {
    setShowEdit(id);
  };

  const handleDeleteRecord = async () => {
    setShowDeleteModal(false);
    if (recordToDeleteId) {
      const response = await deleteData(`/masters/pincode/${recordToDeleteId}`);
      await ErrorNotify(recordToDeleteName);
      setChangeStatus(response);
      setRecordToDeleteId(null);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setRecordToDeleteId(null);
  };

  //toggle/
  const [visible, setVisibel] = useState({
    col0: true,
    col1: true,
    col2: true,
    col3: true,
    col4: true,
    col5: true,
    col6: true,
    col7: true,
  });

  //toggle columnns.........
  const toggleColumn = (event, columnName) => {
    event.preventDefault();
    setVisibel((prev) => ({
      ...prev,
      [columnName]: !prev[columnName],
    }));
  };

  const [country, setCountry] = useState([]);
  const [selectcountry, setselectCountry] = useState(null);
  const [selectstate, setselectState] = useState(null);
  const [state, setState] = useState([]);
  const [selectcity, setselectcity] = useState(null);
  const [city, setcity] = useState([]);
  const GetAllCountry = async () => {
    const response = await Country();

    const option = [];
    if (response.success) {
      response?.data?.map((data) => {
        option.push({
          value: data.id,
          name: "country_id",
          label: data.name,
        });
      });
    }
    setCountry(option);
  };

  useEffect(() => {
    GetAllCountry();
  }, []);

  async function getSelectedState(id) {
    const response = await State(id.value);
    const option = [];
    if (response.success) {
      response?.data?.map((data) => {
        option.push({
          value: data.id,
          name: "state_id",
          label: data.name,
        });
      });
    }
    setState(option);
  }

  async function getSelectedCity(id) {
    const response = await City(id.value);
    const option = [];
    if (response.success) {
      response?.data?.map((data) => {
        option.push({
          value: data.id,
          name: "city_id",
          label: data.name,
        });
      });
    }
    setcity(option);
  }

  return (
    <>
      <div className="Dashboard">
        <Header title={"Pincode"} link={"/masters/pincode"} />
        <section className="Advancemain-advancedashboard">
          <div className="col-xxl-12 col-xl-12  ">
            <section className="Tabels tab-radio tab-radio mt-5">
              <div className="">
                <div className="row">
                  <div className="d-flex">
                    <div className="add me-3">
                      {/* {isAllow.includes(26) ? (
                        
                      ) : (
                        <></>
                      )} */}

                      <Link type="button" className="btn btn-add pe-3">
                        <div onClick={() => handleShow()}>
                          <img src={plus} className="plus me-2 ms-0" alt="" />
                          Add Pincode
                        </div>
                      </Link>
                    </div>

                    <div className="add">
                      <div className="dropdown">
                        <button
                          className="btn btn-columns dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img src={colunms} className="columns " alt="" />
                          Column Selection{" "}
                          <img src={top} className="top ms-1" alt="" />{" "}
                        </button>
                        <ul className="dropdown-menu">
                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col1")}
                              href="#"
                            >
                              Sr. No.
                              {visible?.col1 ? (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col2")}
                              href="#"
                            >
                              Country
                              {visible?.col2 ? (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col3")}
                              href="#"
                            >
                              State
                              {visible?.col3 ? (
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>

                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col4")}
                              href="#"
                            >
                              City
                              {visible?.col4 ? (
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>

                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col5")}
                              href="#"
                            >
                              Pincode
                              {visible?.col5 ? (
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>

                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col6")}
                              href="#"
                            >
                              Status
                              {visible?.col6 ? (
                                <FontAwesomeIcon
                                  className="ms-5"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col7")}
                              href="#"
                            >
                              Action
                              {visible?.col7 ? (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="border-line mt-3"></div>
                  <div className="row mt-4">
                    <div className=" col-xxl-3 col-xl-12 col-lg-12 col-md-12 col-12">
                      <div className="d-flex">
                        <div className="show me-2">
                          <p className="show mt-1">Show</p>
                        </div>
                        <div className="number me-2">
                          <select
                            className="form-select form-select-sm"
                            aria-label=".form-select-sm example"
                            onChange={(e) => setperPage(e.target.value)}
                          >
                            {option?.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="entries">
                          <p className="show mt-1">entries</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-xxl-9   col-xl-12 col-lg-12  col-md-12 col-12">
                      <div className="d-flex justify-content-end">
                        <div className="sowing me-2">
                          <p className="show mt-2">{`Showing ${Math.min(
                            (currentPage - 1) * perPage + 1
                          )} to ${Math.min(
                            currentPage * perPage,
                            data?.data?.totalEntries
                          )} of ${data?.data?.totalEntries} entries`}</p>
                        </div>

                        <div className="num me-2">
                          <Select
                            // name="country_id"
                            // className="input-mandatory"
                            onChange={(e) => {
                              setselectCountry(e);
                              getSelectedState(e);
                            }}
                            placeholder="Select Country"
                            value={selectcountry}
                            options={country}
                          />
                        </div>
                        <div className="num me-2">
                          <Select
                            // name="country_id"
                            // className="input-mandatory"
                            onChange={(e) => {
                              setselectState(e);
                              getSelectedCity(e);
                            }}
                            placeholder="Select State"
                            value={selectstate}
                            options={state}
                          />
                        </div>
                        <div className="num me-2">
                          <Select
                            // name="country_id"
                            // className="input-mandatory"
                            onChange={(e) => {
                              setselectcity(e);
                            }}
                            placeholder="Select City"
                            value={selectcity}
                            options={city}
                          />
                        </div>
                        <div className="num me-2">
                          <input
                            type="text"
                            className="form-control"
                            id=""
                            value={search}
                            onChange={(e) => {
                              setSearch(e.target.value);
                            }}
                          />
                        </div>
                        <div className="Search me-2">
                          <button
                            type="button"
                            onClick={getDataAll}
                            className="btn btn-search"
                          >
                            <img src={search1} className="search" alt="" />
                          </button>
                        </div>

                        <div className="Search-1">
                          <button
                            type="button"
                            onClick={() => {
                              setSearch("");
                              setselectCountry(null);
                              setselectState(null);
                              setselectcity(null);
                              setReset(!reset);
                            }}
                            className="btn btn-reset"
                          >
                            <FontAwesomeIcon icon={faRotate} />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-line mt-3"></div>
                <div className="row mt-3">
                  <div className="data table-responsive">
                    <Table striped bordered hover responsive center>
                      <thead>
                        <tr className="">
                          {visible.col1 && (
                            <th className="tax-name">Sr. No.</th>
                          )}
                          {visible.col2 && (
                            <th className="tax-name">Country</th>
                          )}
                          {visible.col3 && <th className="tax-name">State</th>}
                          {visible.col4 && <th className="tax-name">City</th>}
                          {visible.col5 && (
                            <th className="tax-name">Pincode</th>
                          )}

                          {visible.col6 && <th className="tax-name">Status</th>}

                          {visible.col7 && <th className="active">Action</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {data?.data?.data?.map((d, index) => {
                          return (
                            <tr className="" key={index}>
                              {visible.col1 && <td>{++index}.</td>}
                              {visible.col2 && <td>{d?.country?.name}</td>}
                              {visible.col3 && <td>{d?.state?.name}</td>}
                              {visible.col4 && <td>{d?.city?.name}</td>}

                              {visible.col5 && <td>{d?.name}</td>}
                              {visible.col6 && (
                                <td>
                                  <div className="form-check form-switch">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      role="switch"
                                      checked={d.status}
                                      onChange={() => {
                                        ChangeStatus(d.id);
                                      }}
                                      id={`flexSwitchCheckDefault${d?.id}`}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={`flexSwitchCheckDefault${d?.id}`}
                                    >
                                      {d.status ? "Active" : "Inactive"}
                                    </label>
                                  </div>
                                </td>
                              )}

                              {visible.col6 && (
                                <td>
                                  <div className="d-flex">
                                    <Button
                                      onClick={() => handleShow1(d?.id)}
                                      type="button"
                                      className="btn btn-primary me-1"
                                    >
                                      <img src={pen} className="pen" alt="" />
                                    </Button>

                                    <DeletButton
                                      showDeleteRecord={showDeleteRecord}
                                      id={d?.id}
                                      name={d?.name}
                                    />
                                  </div>
                                </td>
                              )}
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </div>

      {show ? (
        <AddOffCanvance
          handleClose={handleClose}
          setShow={setShowAdd}
          show={show}
        />
      ) : (
        ""
      )}

      {show1 ? (
        <EditOffCanvance
          handleClose={handleClose1}
          setShow={setShowEdit}
          show={show1}
        />
      ) : (
        ""
      )}
      <ModalDelete
        show={showDeleteModal}
        handleDeleteRecord={handleDeleteRecord}
        handleDeleteCancel={handleDeleteCancel}
      />

      <Toaster position="top-right" />
    </>
  );
};

export default Tables;
