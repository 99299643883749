import React from "react";
import LoginPage from "./LoginPage/LoginPage.js";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
// import { Context } from "../../utils/context.js";
import { Context } from "../../../utils/context.js";

const Login = () => {
  const { signin, usertype } = useContext(Context);
  const navigate = useNavigate();
  // console.log(usertype);
  // if (signin) {
  //   if (usertype === "admin") {
  //     navigate("/masters/country");
  //   } else if (usertype === "tele-caller") {
  //     navigate("/lead-main-advancedashboard");
  //   } else {
  //     return <LoginPage />;
  //   }
  // } else {
  //   return <LoginPage />;
  // }

  if (signin) {
    if (usertype === "tele-caller") {
      navigate("/lead-main-advancedashboard");
    } else if (usertype?.length) {
      navigate("/dashboard");
    } else {
      return <LoginPage />;
    }
  } else {
    return <LoginPage />;
  }

  // return (
  //   <div>
  //     {signin ? (
  //       usertype === "admin" ? (
  //         navigate("/masters/country")
  //       ) : (
  //         <LoginPage />
  //       )
  //     ) : (
  //       <LoginPage />
  //     )}
  //   </div>
  // );
};

export default Login;
