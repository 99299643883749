import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";

import Login from "./Components/Admin/Login/Login";

import Dashboard from "./Components/Admin/DashBoard/DashBoard";
import Sidebar from "./Components/Admin/Sidebar/Sidebar";
import Header from "./Components/Admin/Header/Header";

// Masters

// import PrimarySkills from "./Components/Admin/Masters/PrimarySkills/Tables";
// import SecondarySkills from "./Components/Admin/Masters/SecondarySkills/Tables";

// Talent Pool

// Recruiters

// import Country from "./Components/Admin/Masters/Country/Tables";
import Country from "./Components/Admin/Masters/Country lang/Tables";

// import State from "./Components/Admin/Masters/State/Tables";
import State from "./Components/Admin/Masters/StateLangauge/Tables";

// import City from "./Components/Admin/Masters/City/Tables";
import City from "./Components/Admin/Masters/cityLangauge/Tables";

import Pincode from "./Components/Admin/Masters/Pincode/Tables";
// import Pincode from "./Components/Admin/Masters/PincodeLangauge/Tables";

import Admin_Earnings from "./Components/Admin/Masters/Admin_Earnings/Table";
import App_setup from "./Components/Admin/Masters/App_setup/Tables";
// import RiderPartner from "./Components/Admin/Masters/RiderPartner/Tables";
import RiderPartner from "./Components/Admin/Masters/RiderpartenetLang/Tables";

import Vehicle from "./Components/Admin/Masters/Vehicle/Table";
import AssignVehicle from "./Components/Admin/Masters/AssignVehicle/Table";

import VehicleCategory from "./Components/Admin/Masters/VehicleCatrgory/Table";
import VehicleFare from "./Components/Admin/Masters/VehicleFare/Table";

import Riders from "./Components/Admin/Customers/Riders/Tables";
import Users from "./Components/Admin/Customers/Users/Tables";
import Rides from "./Components/Admin/Customers/Rides/Table";

import User_Help_Type from "./Components/Admin/Masters/User_Help_Type/Table";
// import User_Help_Type from "./Components/Admin/Masters/UserHelpTypeLang/Table";

import User_Help_Sub_Type from "./Components/Admin/Masters/User_Help_Sub_Type/Table";
// import User_Help_Sub_Type from "./Components/Admin/Masters/UserHelpSubTypeLangauge/Table";

import UserHelp from "./Components/Admin/Masters/UserHelp/Table";
// import UserHelp from "./Components/Admin/Masters/UserHelpLang/Table";

import UserAdvertisement from "./Components/Admin/Masters/UserAdvertisement/Table";

// import Rider_Help_Type from "./Components/Admin/Masters/Rider_Help_Type/Table";
import Rider_Help_Type from "./Components/Admin/Masters/RiderHelpTypeLang/Table";

// import RiderHelp from "./Components/Admin/Masters/RiderHelp/Table";
import RiderHelp from "./Components/Admin/Masters/RiderHelpLang/Table";

import RiderAdvertisement from "./Components/Admin/Masters/RiderAdvertisement/Table";
import Driver_Earnings from "./Components/Admin/Drivers/Driver_Earnings/Table";
import Driver_Velicles from "./Components/Admin/Drivers/Driver_Vehicles/Table";
import Driver_List from "./Components/Admin/Drivers/Driver_List/Table";

// Lead Dashborad
// import Lead from "./Components/lead-dashboard/Lead";

const App = () => {
  const location = useLocation();
  const [headerText, setHeaderText] = useState(location.pathname);

  useEffect(() => {
    setHeaderText(location.pathname);
  });
  return (
    <div className="main d-flex">
      {headerText === "/" || headerText === "/lead-dashboard" ? (
        <></>
      ) : (
        <Sidebar />
      )}

      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/Header" element={<Header />} />
        <Route path="/dashboard" element={<Dashboard />} />
        {/* Masters Start */}
        {/*Language */}
        <Route path="/masters/app-setup" element={<App_setup />} />
        {/*admin-earnings */}
        <Route path="/masters/admin-earnings" element={<Admin_Earnings />} />
        {/*Country */}
        <Route path="/masters/country" element={<Country />} />
        {/*State */}
        <Route path="/masters/state" element={<State />} />
        {/*City */}
        <Route path="/masters/city" element={<City />} />
        {/*Pincode */}
        <Route path="/masters/pincode" element={<Pincode />} />
        {/*Riders */}
        <Route path="/customers/riders" element={<Riders />} />
        {/*Driver Earnings */}
        <Route path="/driver/driver-earnings" element={<Driver_Earnings />} />
        {/*Driver Earnings */}
        <Route path="/driver/driver-vehicles" element={<Driver_Velicles />} />
        {/*Driver List */}
        <Route path="/driver/driver-list" element={<Driver_List />} />
        {/*Users */}
        <Route path="/customers/users" element={<Users />} />

        <Route path="/customers/rides" element={<Rides />} />

        <Route path="/masters/user-help-type" element={<User_Help_Type />} />
        <Route
          path="/masters/user-help-sub-type"
          element={<User_Help_Sub_Type />}
        />
        <Route path="/masters/user-help" element={<UserHelp />} />
        <Route
          path="/masters/user-advertisement"
          element={<UserAdvertisement />}
        />
        <Route path="/masters/rider-help-type" element={<Rider_Help_Type />} />
        <Route path="/masters/rider-help" element={<RiderHelp />} />
        <Route
          path="/masters/rider-advertisement"
          element={<RiderAdvertisement />}
        />
        <Route
          path="/masters/rider-partner"
          element={<RiderPartner />}
        />
        <Route
          path="/masters/vehicle"
          element={<Vehicle />}
        />

        <Route
          path="/masters/assign-vehicle"
          element={<AssignVehicle />}
        />

        <Route
          path="/masters/category-vehicle"
          element={<VehicleCategory />}
        />

        <Route
          path="/masters/fare-vehicle"
          element={<VehicleFare />}
        />

        {/* Lead Dashborad */}
      </Routes>
    </div>
  );
};

export default App;
