import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./Dashboard.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Header from "../Header/Header";
import Buckets from "./Buckets/Buckets";
import Piechart from "./Piechart/Piechart";
library.add(fas);
function Dashboard() {

  return (
    <div className="Dashboard">
      <Header title={"Dashboard"} link={"/"} />
      <Buckets />
      <div className="graph-holder">
        <div className="row">
          <div className="col-md-6">
            <div className="chart-holder">
              <Piechart  headingText={"Number of Rides"}/>
            </div>
          </div>

          {/* <div className="col-md-6">
            <div className="chart-holder">
              <Piechart headingText={"Number of customers"}/>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
