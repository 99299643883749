import React, { useContext } from "react";
import { useState, useEffect } from "react";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { Container, Row, Col, Form, InputGroup, Table } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./ViewOffCanvance.css";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import "react-phone-input-2/lib/bootstrap.css";
import { Context } from "../../../../utils/context";
import 'bootstrap/dist/css/bootstrap.min.css';

library.add(fas);

const ViewOffCanvance = (props) => {
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { getData, IMG_URL } = useContext(Context);

  const [data, setData] = useState(props.data);
  const [formData, setFormData] = useState();
  // console.log(data,"data");
  const GetEditData = async () => {
    const response = await getData(`/customers/rides/${data}`);
    setFormData(response?.data);
  };

  useEffect(() => {
    GetEditData();
  }, []);
  console.log(formData, "formData");
  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%", height: "auto" }}
        placement={"end"}
        onHide={props.handleClose}
        className="offcan"
      >
        <Container className="tabss-main">
          <Offcanvas.Header closeButton></Offcanvas.Header>
          <Offcanvas.Body>
            <Card className="shadow-lg p-3 mb-5 bg-white rounded">
              <Card.Body>
                <Card.Title>
                  <h3>Ride Details </h3>
                </Card.Title>

                <Box sx={{ width: "100%", typography: "body1" }}>
                <div className="row mt-3" style={{ overflowX: 'scroll' }}>
                    <div className="data table-responsive">
                      <Table striped bordered hover responsive center>
                        <thead>
                          <tr className="">
                            {/* <th className="tax-name">Sr. No.</th> */}

                            <th className="tax-name">Pickup Latitude </th>

                            <th className="tax-name">Pickup Longitude </th>

                            <th className="tax-name">Pickup Address </th>

                            <th className="tax-name">Drop Latitude </th>

                            <th className="tax-name">Drop Longitude </th>

                            <th className="tax-name">Drop Address </th>

                            <th className="tax-name">Distance </th>

                            <th className="tax-name">Fare </th>

                            <th className="tax-name">Time </th>

                            <th className="tax-name">Start Time </th>

                            <th className="tax-name">End Time </th>

                            {/* <th className="tax-name">Rider Status </th> */}

                            <th className="tax-name">Payment Type </th>

                            <th className="tax-name">Customer</th>

                            <th className="tax-name">Rider</th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* {data?.map((d, index) => { */}
                          {/* return ( */}
                          {/* <tr className=""> */}
                          {/* <td>{++index}</td>
                                <td>{d?.pickup_lat}</td>
                                <td>{d?.pickup_long}</td>
                                <td>{d?.pickup_add}</td>
                                <td>{d?.drop_lat}</td>
                                <td>{d?.drop_long}</td>
                                <td>{d?.drop_add}</td>
                                <td>{d?.distance}</td>
                                <td>{d?.fire}</td>
                                <td>{d?.time}</td>
                                <td>{d?.start_time}</td>
                                <td>{d?.end_time}</td>
                                <td>{d?.rider_status?.name}</td>
                                <td>{d?.payment_type?.name}</td>
                                <td>{d?.user?.f_name}</td>
                                <td>{d?.rider?.f_name}</td> */}

                          {/* </tr> */}
                          {/* ); */}
                          {/* })} */}
                          <tr>
                          {/* <td>{++index}</td> */}
                            <td>
                              {formData?.pickup_lat}</td>
                            <td>{formData?.pickup_long}</td>
                            <td>{formData?.pickup_add}</td>
                            <td>{formData?.drop_lat}</td>
                            <td>{formData?.drop_long}</td>
                            <td>{formData?.drop_add}</td>
                            <td>{formData?.distance}</td>
                            <td>{formData?.fire}</td>
                            <td>{formData?.time}</td>
                            <td>{formData?.start_time}</td>
                            <td>{formData?.end_time}</td>
                            {/* <td>{formData?.rider_status?.name}</td> */}
                            <td>{formData?.payment_type?.name}</td>
                            <td>{formData?.user?.f_name}</td>
                            <td>{formData?.rider?.f_name}</td>

                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Box>
              </Card.Body>
            </Card>
          </Offcanvas.Body>
        </Container>
      </Offcanvas>
    </>
  );
};

export default ViewOffCanvance;
