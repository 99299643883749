import React, { useContext, useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Context } from "../../../../utils/context";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useForm } from "react-hook-form";
import Card from "react-bootstrap/Card";
import Select from "react-select";
import ModalSave from "../../../common/ModalSave";
import Validation from "../../../common/FormValidation";
import { CancelButton, SaveButton } from "../../../common/Button";
import JoditEditor from "jodit-react";
import { Container, Row, Col, Form, InputGroup } from "react-bootstrap";
import { allUserhelpSubtype, allUserhelptype, Languages ,allRiderhelptype} from "../../../../utils/apis/masters/masters";
import classNames from "classnames";

const AddOffCanvance = (props) => {
  const { postData } = useContext(Context);
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const [country, setCountry] = useState([]);
  const [userHelpSubType, setUserHelpSubType] = useState([]);
  const [formData, setFormData] = useState({
    user_help_type_id: "",
    user_help_sub_type_id: "",
    question: "",
    answer: "",
  });

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const handleDescriptionChange = (langId, value) => {
    setFormData({
      ...formData,
      [langId]: {
        ...formData[langId],
        answer: value,
      },
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "rider_help_type_id" || name === "user_help_sub_type_id") {
      if (name === "rider_help_type_id") {
        setFormData({
          ...formData,
          [name]: value,
          user_help_sub_type_id: "",
        });
        // getSelectedState(value);
      } else {
        setFormData({ ...formData, [name]: value });
      }
    } else if (name.startsWith("question_")) {
      const langId = name.split("_")[1];
      setFormData({
        ...formData,
        [langId]: {
          ...formData[langId],
          question: value,
        },
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSelectChange = (selectedOption, actionMeta) => {
    const { name } = actionMeta;
    setFormData({
      ...formData,
      [name]: selectedOption,
    });
    if (name === "rider_help_type_id") {
      getSelectedState(selectedOption.value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = await Validation.FormValidation({
      classname: ".stateclass",
    });

    const validationErrorsSelect = Validation.SelectValidation({
      classname: ".stateclass",
    });

    if (validationErrors && validationErrorsSelect) {
      try {
        const response = await postData("/masters/rider-help", formData);
        if (response.success) {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        } else {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const {
    control,
    formState: { errors },
  } = useForm();

  async function getSelectedState(id) {
    const response = await allUserhelpSubtype(id);

    if (response.success) {
      setUserHelpSubType(response.data);
    }
  }

  const GetAllCountry = async () => {
    const response = await allRiderhelptype();

    const option = [];
    if (response.success) {
      response?.data?.map((data) => {
        option.push({
          value: data.id,
          name: "rider_help_type_id",
          label: data.name,
        });
      });
    }
    setCountry(option);
  };

  useEffect(() => {
    GetAllCountry();
  }, []);

  

  const errorStyle = {
    color: "red",
    marginLeft: "5px",
  };

  const [countLang, setCountLang] = useState([]);

  const getLanguges = async () => {
    const response = await Languages();
    setCountLang(response.data);
    const initialFormData = response.data.reduce((acc, lang) => {
      acc[lang.id] = { question: "", answer: "" };
      return acc;
    }, {});
    setFormData((prevFormData) => ({ ...prevFormData, ...initialFormData ,count: response?.data }));
  };

  useEffect(() => {
    getLanguges();
  }, []);

  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton>
          {/* <Offcanvas.Title>Add State</Offcanvas.Title> */}
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="shadow-lg p-3 mb-5 bg-white rounded">
            <Card.Body>
              <Card.Title>Add User Help Sub Type</Card.Title>
              <hr />
              <Container>
                <Row>
                  <Col md={12}>
                    <Container>
                      <Row className="">
                        <Col xxl={12} xl={12} md={10}>
                          <Form
                            onSubmit={handleSubmit}
                            role="form"
                            className="stateclass"
                          >
                            <Row>
                            <Col md={6}>
                                <div className="main-form-section mt-5">
                                  <Row className="row justify-content-center mb-2">
                                    <Form.Label column sm={3}>
                                      Rider Help Type{" "}
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Select
                                        name="rider_help_type_id"
                                        className="input-mandatory"
                                        onChange={handleSelectChange}
                                        placeholder="Select Rider Help Type"
                                        value={formData.rider_help_type_id}
                                        options={country}
                                      />
                                      <span className="error-message"></span>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>

                            

                              {countLang?.map((data, index) => (
                                <Col md={12} key={index}>
                                  <div className="main-form-section mt-5">
                                    <Row className="justify-content-center">
                                      <Form.Label column sm={3}>
                                        Question ({data?.name})
                                      </Form.Label>
                                      <Col sm={6}>
                                        <Form.Group>
                                          <InputGroup>
                                            <Form.Control
                                              type="text"
                                              name={`question_${data.id}`}
                                              placeholder={`Enter question in ${data?.name}`}
                                              value={formData[data.id]?.question || ""}
                                              onChange={handleChange}
                                              id="inputEmail3"
                                              className="input-mandatory"
                                            />
                                          </InputGroup>
                                          {errors[`question_${data.id}`] && (
                                            <span style={errorStyle}>
                                              {errors[`question_${data.id}`] + " in " + data.name}
                                            </span>
                                          )}
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>
                              ))}

                              {countLang?.map((data, index) => (
                                <Col md={12} key={index}>
                                  <div className="main-form-section mt-5">
                                    <Row className="justify-content-center">
                                      <Form.Label column sm={3}>
                                        Answer ({data?.name})
                                      </Form.Label>
                                      <Col sm={6}>
                                        <Form.Group>
                                          <InputGroup>
                                            <JoditEditor
                                              ref={editor}
                                              value={formData[data.id]?.answer || ""}
                                              tabIndex={2}
                                              className="custom-jodit-editor"
                                              onBlur={(newContent) =>
                                                handleDescriptionChange(data.id, newContent)
                                              }
                                            />
                                          </InputGroup>
                                          {errors[`answer_${data.id}`] && (
                                            <span style={errorStyle}>
                                              {errors[`answer_${data.id}`] + " in " + data.name}
                                            </span>
                                          )}
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>
                              ))}

                              <Row className="mt-5 pb-3">
                                <div className="d-flex justify-content-center">
                                  <Link>
                                    <CancelButton name={"cancel"} handleClose={props.handleClose} />
                                  </Link>
                                  <SaveButton name={"save"} handleSubmit={handleSubmit} />
                                </div>
                              </Row>
                            </Row>
                          </Form>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Offcanvas.Body>
      </Offcanvas>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;



// import React, { useContext, useRef } from "react";
// import { useState, useEffect } from "react";
// import "../../Tabels/Tabels.css";
// import { Link } from "react-router-dom";
// import { Context } from "../../../../utils/context";

// import Offcanvas from "react-bootstrap/Offcanvas";
// import { useForm, Controller } from "react-hook-form";
// import Card from "react-bootstrap/Card";
// import Select from "react-select";
// import ModalSave from "../../../common/ModalSave";
// import Validation from "../../../common/FormValidation";
// import { CancelButton, SaveButton } from "../../../common/Button";
// import JoditEditor from "jodit-react";
// import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
// import {
//   allUserhelpSubtype,
//   allUserhelptype,Languages
// } from "../../../../utils/apis/masters/masters";
// import classNames from "classnames";

// const AddOffCanvance = (props) => {
//   const { postData, getData } = useContext(Context);
//   const editor = useRef(null);
//   const [content, setContent] = useState("");
//   const [country, setCountry] = useState([]);
//   const [userHelpSubType, setUserHelpSubType] = useState([]);
//   const [formData, setFormData] = useState({
//     user_help_type_id: "",
//     user_help_sub_type_id: "",
//     question: "",
//     answer: "",
//   });

//   const [showModal, setShowModal] = useState({
//     code: 0,
//     message: "",
//   });

//   const handleDescriptionChange = (value) => {
//     setFormData({ ...formData, answer: value });
//   };

//   // const handleChange = (e, fieldId, fieldType) => {
//   //   if (e?.name == "user_help_type_id" || e?.name == "user_help_sub_type_id") {
//   //     if (e?.name == "user_help_type_id") {
//   //       setFormData({
//   //         ...formData,
//   //         [e.name]: e,
//   //         user_help_sub_type_id: "",
//   //       });
//   //       getSelectedState(e.value);
//   //     } else {
//   //       setFormData({
//   //         ...formData,
//   //         [fieldId]: {
//   //           ...formData[fieldId],
//   //           [fieldType]: e.target.value,
//   //         },
//   //       });
//   //     }
//   //   } else {
//   //     setFormData({
//   //       ...formData,
//   //       [fieldId]: {
//   //         ...formData[fieldId],
//   //         [fieldType]: e.target.value,
//   //       },
//   //     });
//   //   }
//   // };


//   const handleChange = (e) => {
//     if (e?.name == "user_help_type_id" || e?.name == "user_help_sub_type_id") {
//       if (e?.name == "user_help_type_id") {
//         setFormData({
//           ...formData,
//           [e.name]: e,
//           user_help_sub_type_id: "",
//         });
//         getSelectedState(e.value);
//       } else {
//         setFormData({ ...formData, [e.name]: e });
//       }
//     } else {
//       setFormData({ ...formData, [e.target.name]: e.target.value });
//     }
//   };
//   // const handleChange = (e, fieldId, fieldType) => {
//   //   const value = e?.target ? e.target.value : e; // Handle both regular inputs and Select components
//   //   const name = e?.name || fieldId; // Handle both regular inputs and Select components
  
//   //   if (name === "user_help_type_id" || name === "user_help_sub_type_id") {
//   //     // Handle Select components
//   //     setFormData({
//   //       ...formData,
//   //       [name]: value, // Set the value directly for Select components
//   //       user_help_sub_type_id: "", // Reset sub type if changing help type
//   //     });
//   //     if (name === "user_help_type_id") {
//   //       getSelectedState(value); // Fetch sub types for the selected help type
//   //     }
//   //   } else {
//   //     // Handle regular Form.Control components
//   //     setFormData({
//   //       ...formData,
//   //       [fieldId]: {
//   //         ...formData[fieldId],
//   //         [fieldType]: value, // Update specific field in formData
//   //       },
//   //     });
//   //   }
//   // };

//   // const handleChange = (e) => {
//   //   if (e?.name == "user_help_type_id" || e?.name == "user_help_sub_type_id") {
//   //     if (e?.name == "user_help_type_id") {
//   //       setFormData({
//   //         ...formData,
//   //         [e.name]: e,
//   //         user_help_sub_type_id: "",
//   //       });
//   //       getSelectedState(e.value);
//   //     } else {
//   //       setFormData({ ...formData, [e.name]: e });
//   //     }
//   //   } else {
//   //     setFormData({ ...formData, [e.target.name]: e.target.value });
//   //   }
//   // };
  

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const validationErrors = await Validation.FormValidation({
//       classname: ".stateclass",
//     });

//     const validationErrorsSelect = Validation.SelectValidation({
//       classname: ".stateclass",
//     });

//     if (validationErrors && validationErrorsSelect) {
//       try {
//         const response = await postData("/masters/user-help", formData);
//         if (response.success) {
//           setShowModal({ code: response.code, message: response.message });
//           setTimeout(() => {
//             setShowModal(0);
//             props.handleClose();
//           }, 1000);
//         } else {
//           setShowModal({ code: response.code, message: response.message });
//           setTimeout(() => {
//             setShowModal(0);
//             props.handleClose();
//           }, 1000);
//         }
//       } catch (error) {
//         console.error("Error submitting form:", error);
//       }
//     }
//   };

//   const {
//     control,
//     formState: { errors },
//   } = useForm();

//   async function getSelectedState(id) {
//     console.log(id,"id");
//     const response = await allUserhelpSubtype(id.value);

//     if (response.success) {
//       setUserHelpSubType(response.data);
//     }
//   }

//   const GetAllCountry = async () => {
//     const response = await allUserhelptype();

//     const option = [];
//     if (response.success) {
//       response?.data?.map((data) => {
//         option.push({
//           value: data.id,
//           name: "user_help_type_id",
//           label: data.name,
//         });
//       });
//     }
//     setCountry(option);
//   };

//   useEffect(() => {
//     GetAllCountry();
//   }, []);

//   const errorStyle = {
//     color: "red",
//     marginLeft: "5px",
//     // fontSize: "11px",
//   };

//   const [countLang, setCountLang] = useState([]);

//   const getLanguges = async () => {
//     const response = await Languages();
//     setCountLang(response.data);
//     // Initialize formData with empty name and sub_name for each language
//     const initialFormData = response.data.reduce((acc, lang) => {
//       acc[lang.id] = { name: "", sub_name: "" };
//       return acc;
//     }, {});
//     setFormData(initialFormData);
//   };

//   useEffect(() => {
//     getLanguges();
//   }, []);

//   return (
//     <>
//       <Offcanvas
//         show={props.show}
//         style={{ width: "80%" }}
//         placement={"end"}
//         onHide={props.handleClose}
//       >
//         <Offcanvas.Header closeButton>
//           {/* <Offcanvas.Title>Add State</Offcanvas.Title> */}
//         </Offcanvas.Header>
//         <Offcanvas.Body>
//           <Card className="shadow-lg p-3 mb-5 bg-white rounded">
//             <Card.Body>
//               <Card.Title>Add User Help Sub Type</Card.Title>
//               <hr />
//               <Container>
//                 <Row>
//                   <Col md={12}>
//                     <Container>
//                       <Row className="">
//                         <Col xxl={12} xl={12} md={10}>
//                           <Form
//                             onSubmit={handleSubmit}
//                             role="form"
//                             className="stateclass"
//                           >
//                             <Row>
//                             <Col md={6}>
//                                 <div className="main-form-section mt-5">
//                                   <Row className="row justify-content-center mb-2">
//                                     <Form.Label column sm={3}>
//                                       User Help Type{" "}
//                                     </Form.Label>
//                                     <Col sm={6}>
//                                       <Select
//                                         name="user_help_type_id"
//                                         className="input-mandatory"
//                                         onChange={handleChange}
//                                         placeholder="Select User Help Type"
//                                         value={formData.user_help_type_id}
//                                         options={country}
//                                       />
//                                       <span className="error-message"></span>
//                                     </Col>
//                                   </Row>
//                                 </div>
//                               </Col>

//                               <Col md={6}>
//                                 <div className="main-form-section mt-5">
//                                   <Row className="row justify-content-center mb-2">
//                                     <Form.Label column sm={3}>
//                                       User Help Sub Type{" "}
//                                     </Form.Label>
//                                     <Col sm={6}>
//                                       <Select
//                                         name="user_help_sub_type_id"
//                                         className="input-mandatory"
//                                         onChange={handleChange}
//                                         placeholder="Select User Help Sub Type"
//                                         value={formData.user_help_sub_type_id}
//                                         options={userHelpSubType.map(
//                                           (stateOption) => ({
//                                             value: stateOption.id,
//                                             name: "user_help_sub_type_id",
//                                             label: stateOption.name,
//                                           })
//                                         )}
//                                       />
//                                       <span className="error-message"></span>
//                                     </Col>
//                                   </Row>
//                                 </div>
//                               </Col>

                              // {countLang?.map((data, index) => {
                              //       return (
                              //         <Col md={12} key={index}>
                              //           <div className="main-form-section mt-5">
                              //             <Row className="justify-content-center">
                              //               <Form.Label column sm={3}>
                              //                 Title Name {data?.name}
                              //               </Form.Label>
                              //               <Col sm={6}>
                              //                 <Form.Group>
                              //                   <InputGroup>
                              //                     <Form.Control
                              //                       type="text"
                              //                       name={`name-${data.id}`}
                              //                       placeholder={`Enter Title name in ${data?.name}`}
                              //                       value={formData[data.id]?.name || ""}
                              //                       onChange={(e) => handleChange(e, data.id, "name")}
                              //                       id="inputEmail3"
                              //                       className="input-mandatory"
                              //                     />
                              //                   </InputGroup>
                              //                   {errors[data.id] && (
                              //                     <span style={errorStyle}>
                              //                       {errors[data.id] + " in " + data.name}
                              //                     </span>
                              //                   )}
                              //                 </Form.Group>
                              //               </Col>
                              //             </Row>
                              //           </div>
                              //         </Col>
                              //       );
                              //     })}

                              //     {countLang?.map((data, index) => {
                              //       return (
                              //         <Col md={12} key={index}>
                              //           <div className="main-form-section mt-5">
                              //             <Row className="justify-content-center">
                              //               <Form.Label column sm={3}>
                              //                 Sub Title Name {data?.name}
                              //               </Form.Label>
                              //               <Col sm={6}>
                              //                 <Form.Group>
                              //                   <InputGroup>
                              //                     <Form.Control
                              //                       type="text"
                              //                       name={`sub_name-${data.id}`}
                              //                       placeholder={`Enter Sub Title name in ${data?.name}`}
                              //                       value={formData[data.id]?.sub_name || ""}
                              //                       onChange={(e) => handleChange(e, data.id, "sub_name")}
                              //                       id="inputEmail3"
                              //                       className="input-mandatory"
                              //                     />
                              //                   </InputGroup>
                              //                   {errors[data.id] && (
                              //                     <span style={errorStyle}>
                              //                       {errors[data.id] + " in " + data.name}
                              //                     </span>
                              //                   )}
                              //                 </Form.Group>
                              //               </Col>
                              //             </Row>
                              //           </div>
                              //         </Col>
                              //       );
                              //     })}


//                               {/* <Col md={6}>
//                                 <div className="main-form-section mt-5">
//                                   <Row className="justify-content-center">
//                                     <Form.Label column sm={3}>
//                                       Question
//                                     </Form.Label>
//                                     <Col sm={6}>
//                                       <Form.Group>
//                                         <InputGroup>
//                                           <Form.Control
//                                             type="text"
//                                             name="question"
//                                             value={formData?.question}
//                                             onChange={handleChange}
//                                             placeholder="question"
//                                             id="inputEmail3"
//                                             className="input-mandatory"
//                                           />
//                                         </InputGroup>
//                                         <span className="error-message"></span>
//                                       </Form.Group>
//                                     </Col>
//                                   </Row>
//                                 </div>
//                               </Col>

//                               <Col md={12}>
//                                 <div className="main-form-section mt-2">
//                                   <Row className="justify-content-center">
//                                     <Form.Label column sm={3}>
//                                       Answer
//                                     </Form.Label>
//                                     <Col sm={9}>
//                                       <Form.Group>
//                                         <InputGroup>
//                                           <JoditEditor
//                                             ref={editor}
//                                             value={formData.answer}
//                                             tabIndex={2}
//                                             className="custom-jodit-editor"
//                                             onBlur={(newContent) =>
//                                               setContent(newContent)
//                                             }
//                                             onChange={handleDescriptionChange}
//                                           />
//                                         </InputGroup>
//                                         {errors?.answer && (
//                                           <span style={errorStyle}>
//                                             {errors?.answer}
//                                           </span>
//                                         )}
//                                       </Form.Group>
//                                     </Col>
//                                   </Row>
//                                 </div>
//                               </Col> */}

//                               {/* </Row> */}

//                               <Row className="mt-5 pb-3">
//                                 <div className="d-flex justify-content-center">
//                                   <Link>
//                                     <CancelButton
//                                       name={"cancel"}
//                                       handleClose={props.handleClose}
//                                     />
//                                   </Link>
//                                   <SaveButton
//                                     name={"save"}
//                                     handleSubmit={handleSubmit}
//                                   />
//                                 </div>
//                               </Row>
//                               {/* </Col> */}
//                             </Row>
//                           </Form>
//                         </Col>
//                       </Row>
//                     </Container>
//                   </Col>
//                 </Row>
//               </Container>
//             </Card.Body>
//           </Card>
//         </Offcanvas.Body>
//       </Offcanvas>

//       <ModalSave
//         message={showModal.message}
//         showErrorModal={showModal.code ? true : false}
//       />
//     </>
//   );
// };

// export default AddOffCanvance;
