import React, { useContext } from "react";
import { useState, useEffect } from "react";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { Container, Row, Col, Form, InputGroup } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./ViewOffCanvance.css";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import "react-phone-input-2/lib/bootstrap.css";
import { Context } from "../../../../utils/context";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { CancelButton, SaveButton } from "../../../common/Button";
library.add(fas);

const ViewOffCanvance = (props) => {
  const [value, setValues] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValues(newValue);
  };
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });
  const { getData, IMG_URL, editStatusData, postData } = useContext(Context);

  const [data, setData] = useState(props.data);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    getValues,setValue
  } = useForm();

  console.log(data, "data data");
  const [selectedOption, setSelectedOption] = useState(null);
  const handleChangestatus = (selectedOption) => {
    setSelectedOption(selectedOption.value);
  };
  console.log(selectedOption, "selectedOption");
  // const ChangeStatus = async () => {
  //   const response = await postData(`/customers/riders/${data.id}`, { verified: selectedOption });
  //   // setChangeStatus(response);
  //   props.getDataAll()
  // };

  // useEffect(() => {
  //   ChangeStatus();

  // }, [selectedOption]);

  const onSubmit = async (d) => {
    try {
      const dataToSend = new FormData();
      dataToSend.append("verified", d?.stage?.value)
      // dataToSend.append("email", getValues("email"))
      const response = await postData(`/customers/riders/update/${data.id}`, dataToSend);
      console.log(response);
      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
        props.getDataAll()
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (data.verified !== null) {
      setValue("stage", data.verified === 1 ? { value: 1, label: "Accepted" } : { value: 0, label: "Rejected" });
    }
  }, [data.verified]);
  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%", height: "auto" }}
        placement={"end"}
        onHide={props.handleClose}
        className="offcan"
      >
        <Container className="tabss-main">
          <Offcanvas.Header closeButton></Offcanvas.Header>
          <Offcanvas.Body>
            <Card className="shadow-lg p-3 mb-5 bg-white rounded">
              <Card.Body>
                <Card.Title>
                  <h3>Rider Details </h3>
                </Card.Title>

                <Box sx={{ width: "100%", typography: "body1" }}>
                  <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                      >
                        <Tab label="Personal Details" value="1" />
                        {/* <Tab label="Working Type" value="2" />
                        <Tab label="Industry Details" value="3" />
                        <Tab label="Firm Details" value="4" />
                        <Tab label="Bank Details" value="5" /> */}
                      </TabList>
                    </Box>
                    <TabPanel value="1">
                      <div>
                        <div>
                          <h3>Personal Details</h3>
                        </div>

                        <div>
                          <Row>
                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Name</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.f_name}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Contact Number</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.contact_no}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Email</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.email}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Country</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={
                                          data?.users_detail?.country?.name
                                        }
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>State</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.users_detail?.state?.name}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>City</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.users_detail?.city?.name}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Pincode</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={
                                          data?.users_detail?.pincode?.name
                                        }
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Area</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.users_detail?.area?.name}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col lg={4} className="">
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>
                                    Uploaded Profile Photo
                                  </Form.Label>

                                  <div className="image-boxes-main">
                                    <img
                                      className="image-boxes"
                                      src={
                                        IMG_URL +
                                        data?.users_detail?.profile_photo
                                      }
                                      alt="Profile Photo"
                                    ></img>
                                  </div>
                                </Row>
                              </div>
                            </Col>

                            <Col lg={4} className="">
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Uploaded Adhar Card</Form.Label>

                                  <div className="image-boxes-main">
                                    <img
                                      className="image-boxes"
                                      src={
                                        IMG_URL + data?.users_detail?.adhar_card
                                      }
                                      alt="Adhar Card"
                                    ></img>
                                  </div>
                                </Row>
                              </div>
                            </Col>

                            <Col lg={4} className="">
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>
                                    Uploaded Driving License
                                  </Form.Label>

                                  <div className="image-boxes-main">
                                    <img
                                      className="image-boxes"
                                      src={
                                        IMG_URL +
                                        data?.users_detail?.driving_license
                                      }
                                      alt="Driving License"
                                    ></img>
                                  </div>
                                </Row>
                              </div>
                            </Col>

                            <Col lg={4} className="">
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>
                                    Uploaded Registration Certification
                                  </Form.Label>

                                  <div className="image-boxes-main">
                                    <img
                                      className="image-boxes"
                                      src={
                                        IMG_URL +
                                        data?.users_detail
                                          ?.registration_certification
                                      }
                                      alt="Registration Certification"
                                    ></img>
                                  </div>
                                </Row>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </TabPanel>
                   

                    {/* <TabPanel value="2">
                      <div>
                        <div>
                          <h3>Working Type</h3>
                        </div>

                        <div>
                          <Row>
                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Working Type</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={
                                          data?.s_sector?.s_working_type?.name
                                        }
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value="3">
                      <div>
                        <div>
                          <h3>Industry Details</h3>
                        </div>
                        <div>
                          <Row>
                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Categories</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.s_sector?.s_category?.name}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col lg={8}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Sub Categories</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.s_sector?.s_sector_subs
                                          ?.map(
                                            (name) => name?.s_sub_category?.name
                                          )
                                          .join(", ")}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col lg={12} className="">
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Child Sub Category</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.s_sector?.s_sector_child_categories
                                          ?.map(
                                            (name) =>
                                              name?.s_child_category?.name
                                          )
                                          .join(", ")}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value="4">
                      <div>
                        <div>
                          <h3>Firm Details</h3>
                        </div>

                        <div>
                          <Row>
                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>GST Number</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.s_firm_detail?.gst_no}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>PAN Number</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.s_firm_detail?.pan_no}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col lg={4} className=""></Col>

                            <Col lg={4} className="">
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Uploaded GST Document</Form.Label>
                                 
                                  <div className="image-boxes-main">
                                    <img
                                      className="image-boxes"
                                      src={
                                        IMG_URL + data?.s_firm_detail?.gst_image
                                      }
                                    ></img>
                                  </div>
                                </Row>
                              </div>
                            </Col>

                            <Col lg={4} className="">
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Uploaded PAN Document</Form.Label>
                                 
                                  <div className="image-boxes-main">
                                    <img
                                      className="image-boxes"
                                      src={
                                        IMG_URL + data?.s_firm_detail?.pan_image
                                      }
                                    ></img>
                                  </div>
                                </Row>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>

                      <div className="mt-4">
                        <div>
                          <h3>Firm Address Details</h3>
                        </div>

                        <div>
                          <Row>
                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Firm Name</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.s_firm_detail?.name}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>
                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Firm type</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={
                                          data?.s_firm_detail?.s_firm_type?.name
                                        }
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>

                      <div className="mt-4">
                        <div>
                          <h3>Firm Address Details</h3>
                        </div>

                        <div>
                          {data?.s_firm_address_details?.map((val, index) => (
                            <Row key={index}>
                              <Col lg={4}>
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label>Address Type</Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="text"
                                          value={val?.s_firm_address_type?.name}
                                          disabled
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Row>
                                </div>
                              </Col>
                              <Col lg={4}>
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label>Address Line 1</Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="text"
                                          value={val?.add_line_one}
                                          disabled
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Row>
                                </div>
                              </Col>

                              <Col lg={4}>
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label>Address Line 2</Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="text"
                                          value={val?.add_line_two}
                                          disabled
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Row>
                                </div>
                              </Col>

                              <Col lg={4}>
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label>Country</Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="text"
                                          value={val?.country?.name}
                                          disabled
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Row>
                                </div>
                              </Col>

                              <Col lg={4}>
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label>State</Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="text"
                                          value={val?.state?.name}
                                          disabled
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Row>
                                </div>
                              </Col>

                              <Col lg={4}>
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label>City/District</Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="text"
                                          value={val?.city?.name}
                                          disabled
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Row>
                                </div>
                              </Col>

                              <Col lg={4}>
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label>PinCode</Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="text"
                                          value={val?.pincode?.name}
                                          disabled
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Row>
                                </div>
                              </Col>
                            </Row>
                          ))}
                        </div>
                      </div>

                      <div className="mt-4">
                        <div>
                          <h3>Drop Address Details</h3>
                        </div>

                        <div>
                          {data?.s_firm_drop_addresses?.map((val, index) => (
                            <Row key={index}>
                              <Col lg={4}>
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label>Address Line 1</Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="text"
                                          value={val?.add_line_one}
                                          disabled
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Row>
                                </div>
                              </Col>

                              <Col lg={4}>
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label>Address Line 2</Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="text"
                                          value={val?.add_line_two}
                                          disabled
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Row>
                                </div>
                              </Col>

                              <Col lg={4}>
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label>Country</Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="text"
                                          value={val?.country?.name}
                                          disabled
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Row>
                                </div>
                              </Col>

                              <Col lg={4}>
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label>State</Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="text"
                                          value={val?.state?.name}
                                          disabled
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Row>
                                </div>
                              </Col>

                              <Col lg={4}>
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label>City/District</Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="text"
                                          value={val?.city?.name}
                                          disabled
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Row>
                                </div>
                              </Col>

                              <Col lg={4}>
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label>PinCode</Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="text"
                                          value={val?.pincode?.name}
                                          disabled
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Row>
                                </div>
                              </Col>
                            </Row>
                          ))}
                        </div>
                      </div>
                      <div className="mt-4">
                        <div>
                          <h3>Pickup Address Details</h3>
                        </div>

                        <div>
                          {data?.s_firm_pickup_addresses?.map((val, index) => (
                            <Row key={index}>
                              <Col lg={4}>
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label>Address Line 1</Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="text"
                                          value={val?.add_line_one}
                                          disabled
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Row>
                                </div>
                              </Col>

                              <Col lg={4}>
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label>Address Line 2</Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="text"
                                          value={val?.add_line_two}
                                          disabled
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Row>
                                </div>
                              </Col>

                              <Col lg={4}>
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label>Country</Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="text"
                                          value={val?.country?.name}
                                          disabled
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Row>
                                </div>
                              </Col>

                              <Col lg={4}>
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label>State</Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="text"
                                          value={val?.state?.name}
                                          disabled
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Row>
                                </div>
                              </Col>

                              <Col lg={4}>
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label>City/District</Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="text"
                                          value={val?.city?.name}
                                          disabled
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Row>
                                </div>
                              </Col>

                              <Col lg={4}>
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label>PinCode</Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="text"
                                          value={val?.pincode?.name}
                                          disabled
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Row>
                                </div>
                              </Col>
                            </Row>
                          ))}
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value="5">
                      <div>
                        <div>
                          <h3>Bank Details</h3>
                        </div>
                        <div>
                          <Row>
                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Bank Name</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.s_bank_detail?.bank_name}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Bank Account Number</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={
                                          data?.s_bank_detail?.bank_account_no
                                        }
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>IFSC Code</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.s_bank_detail?.ifsc_code}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Branch Name</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.s_bank_detail?.branch_name}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>MICR Code</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.s_bank_detail?.micr_code}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </TabPanel> */}
                  </TabContext>
                </Box>
              </Card.Body>
            </Card>
            
          </Offcanvas.Body>
          <Col md={4}>
                      <div className="main-form-section mt-3">
                        <Form.Group>
                          <Form.Label>Take an action</Form.Label>
                          <InputGroup>
                            <Controller
                              name="stage" 
                              control={control}
                              // rules={{ required: "Take an Action" }}
                              render={({ field }) => (
                                <Select
                                  isDisabled={data.verified !== null}                              
                                  {...field}
                                  
                                  styles={{
                                    control: (baseStyles) => ({
                                      ...baseStyles,
                                      borderColor: errors.stage ? "red" : baseStyles.borderColor,
                                    }),
                                  }}
                                  options={[
                                    { value: 1, name: "option", label: "Accepted" },
                                    { value: 0, name: "option", label: "Reject" },
                                  ]}
                                />
                              )}
                            />
                          </InputGroup>
                          {errors.stage && <p style={{ color: "red" }}>{errors.stage.message}</p>}
                        </Form.Group>
                      </div>
                    </Col>
                    <Row className="mt-5 pb-3">
            <div className="d-flex justify-content-center">

              <CancelButton
                name={"Back"}
                handleClose={props.handleClose}
              />


              <SaveButton
                name={"save"}
                handleSubmit={handleSubmit(onSubmit)}
              />
            </div>
          </Row>
        </Container>
      </Offcanvas>
    </>
  );
};

export default ViewOffCanvance;
