import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const Country = async () => {
  try {
    return await getData(`/masters/all-country`);
  } catch (error) {
    console.log(error);
  }
};

export const Languages = async () => {
  try {
    return await getData(`/masters/all-languages`);
  } catch (error) {
    console.log(error);
  }
};

export const State = async (id) => {
  console.log(id);
  try {
    return await getData(`/masters/all-states/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const City = async (id) => {
  try {
    return await getData(`/masters/all-city/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const Pincode = async (name) => {
  try {
    return await getData(`/masters/all-pincode/${name}`);
  } catch (error) {
    console.log(error);
  }
};

export const allPincode = async () => {
  try {
    return await getData(`/masters/all-pincode`);
  } catch (error) {
    console.log(error);
  }
};

export const allUserhelptype = async () => {
  try {
    return await getData(`/masters/all-user-help-type`);
  } catch (error) {
    console.log(error);
  }
};

export const allUserhelpSubtype = async (id) => {
  try {
    return await getData(`/masters/all-user-help-sub-type/${id}`);
  } catch (error) {
    console.log(error);
  }
};


export const allRiderhelptype = async () => {
  try {
    return await getData(`/masters/all-rider-help-type`);
  } catch (error) {
    console.log(error);
  }
};


export const Vehicle = async () => {
  try {
    return await getData(`/masters/all-vehicle`);
  } catch (error) {
    console.log(error);
  }
};

export const Rider = async () => {
  try {
    return await getData(`/masters/all-rider`);
  } catch (error) {
    console.log(error);
  }
};

export const VehicleCategory = async () => {
  try {
    return await getData(`/masters/all-vehicle-category`);
  } catch (error) {
    console.log(error);
  }
};

export const AllCity = async () => {
  try {
    return await getData(`/masters/all-city`);
  } catch (error) {
    console.log(error);
  }
};