import React from "react";

const FormValidation = (props) => {
  let msg = "";

  document
    .querySelectorAll(`${props.classname} .input-mandatory`)
    .forEach((element) => {
      if (element.value === "") {
        const formGroup = element.closest(".main-form-section");

        if (formGroup) {
          element.style.cssText =
            "border-radius: 5px; border:#FF0000 1px solid !important;";
          element.required = true;
          const errorSpan = formGroup.querySelector("span.error-message");
          if (errorSpan) {
            errorSpan.textContent = "Please fill the " + element.placeholder;
            errorSpan.style.color = "red";
          }
          msg = "Please fill the mandatory fields before saving.";
        }
      } else {
        const formGroup = element.closest(".main-form-section");
        if (formGroup) {
          element.style.cssText = "";
          element.required = false;
          const errorSpan = formGroup.querySelector("span.error-message");
          if (errorSpan) {
            errorSpan.textContent = "";
            errorSpan.style.color = "";
          }
        }
      }
    });

  if (msg) {
    return false;
  }

  return true;
};

const SelectValidation = (props) => {
  let msg = "";
  document
    .querySelectorAll(`${props.classname} div.input-mandatory`)
    .forEach((element) => {
      const selectInput = element.querySelector(
        "div input [name='country_id'],[name='state_id'],[name='city_id'],[name='zone_id'],[name='lead_status_id'],[name='status'],[name='user_id'],[name='leave_type_id'],[name='start'],[name='end'],[name='employee_id'],[name='priority_id'],[name='channel_id'],[name='role_id'],[name='admin_id'],[name='manager_id'],[name='sales_co_ordinater_id'],[name='sales_executive_id'],[name='country_id']"
      );

      const formGroup = element.closest(".main-form-section");
      if (selectInput?.value === "") {
        if (formGroup) {
          element.style.cssText =
            "border-radius: 5px; border:#FF0000 1px solid !important;";
          element.required = true;
          const errorSpan = formGroup.querySelector("span.error-message");
          if (errorSpan) {
            errorSpan.textContent = "Please Select";
            errorSpan.style.color = "red";
          }
          msg = "Please fill the mandatory fields before saving.";
        }
      } else {
        element.style.cssText = "";
        element.required = false;
        const errorSpan = formGroup.querySelector("span.error-message");
        if (errorSpan) {
          errorSpan.textContent = "";
          errorSpan.style.color = "";
        }
      }
    });

  if (msg) {
    return false;
  }

  return true;
};

export default { FormValidation, SelectValidation };
