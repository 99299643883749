import React from "react";
import circle from "../Admin/assets/icons/circle.png";

const ModalSave = (props) => {
  return (
    <div className="save-modal">
      <div
        className={`modal fade ${props.showErrorModal ? "show" : ""}`}
        style={{ display: props.showErrorModal ? "block" : "none" }}
        id="exampleModal1"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="circle justify-content-end">
                <img src={circle} className="circle-img mb-2" alt="" />
              </div>
              <h1 className="add-success text-center">{props.message}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalSave;
