import React, { useContext, useRef } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import Offcanvas from "react-bootstrap/Offcanvas";
import Card from "react-bootstrap/Card";
import { useForm, Controller } from "react-hook-form";
import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import Select from "react-select";
import { CancelButton, SaveButton } from "../../../common/Button";
import ModalSave from "../../../common/ModalSave";
import Validation from "../../../common/FormValidation";
import JoditEditor from "jodit-react";
import {
  allUserhelpSubtype,
  allUserhelptype,Languages,allRiderhelptype
} from "../../../../utils/apis/masters/masters";
import classNames from "classnames";
library.add(fas);

const EditOffCanvance = (props) => {
  const { postData, getData } = useContext(Context);
  const editor = useRef(null);
  const id = props.show;
  const [country, setCountry] = useState([]);
  const [userHelpSubType, setUserHelpSubType] = useState([]);
  const [formDataSend, setFormDataSend] = useState({});
  const [formData, setFormData] = useState({
    // count: "",
  });

  const {
    control,
    register,

    watch,
    getValues,
    formState: { errors },
    reset,
  } = useForm();

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  // const handleDescriptionChange = (value) => {
  //   setFormData({ ...formData, answer: value });
  // };

  const handleDescriptionChange = (langId, value) => {
    setFormData({
      ...formData,
      [langId]: {
        ...formData[langId],
        answer: value,
      },
    });
  };
  // const handleChange = (e) => {
  //   if (e?.name == "user_help_type_id" || e?.name == "user_help_sub_type_id") {
  //     if (e?.name == "user_help_type_id") {
  //       setFormData({
  //         ...formData,
  //         [e.name]: e,
  //         user_help_sub_type_id: "",
  //       });
  //       getSelectedState(e.value);
  //     } else {
  //       setFormData({ ...formData, [e.name]: e });
  //     }
  //   } else {
  //     setFormData({ ...formData, [e.target.name]: e.target.value });
  //   }
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "rider_help_type_id" || name === "user_help_sub_type_id") {
      if (name === "rider_help_type_id") {
        setFormData({
          ...formData,
          [name]: value,
          user_help_sub_type_id: "",
        });
        getSelectedState(value);
      } else {
        setFormData({ ...formData, [name]: value });
      }
    } else if (name.startsWith("question_")) {
      const langId = name.split("_")[1];
      setFormData({
        ...formData,
        [langId]: {
          ...formData[langId],
          question: value,
        },
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };



  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = await Validation.FormValidation({
      classname: ".stateclass",
    });

    const validationErrorsSelect = Validation.SelectValidation({
      classname: ".stateclass",
    });
    if (validationErrors && validationErrorsSelect) {
      try {
        const response = await postData(`/masters/rider-help/${id}`, formData);
        if (response.success) {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        } else {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  // const GetEditData = async () => {
  //   const response = await getData(`/masters/user-help/${id}`);

  //   setFormData(response?.data);
  // };

  useEffect(() => {
    GetEditData();
  }, []);

  async function getSelectedState(id) {
    const response = await allUserhelpSubtype(id);

    if (response.success) {
      setUserHelpSubType(response.data);
    }
  }

  const handleSelectChange = (selectedOption, actionMeta) => {
    const { name } = actionMeta;
    setFormData({
      ...formData,
      [name]: selectedOption,
    });
    // if (name === "user_help_type_id") {
    //   getSelectedState(selectedOption);
    // }
  };
  const GetAllCountry = async () => {
    const response = await allRiderhelptype();
    const option = [];
    if (response.success) {
      response?.data?.map((data) => {
        option.push({
          value: data.id,
          name: "rider_help_type_id",
          label: data.name,
        });
      });
    }
    setCountry(option);
  };

  useEffect(() => {
    GetAllCountry();
  }, []);


  const errorStyle = {
    color: "red",
    marginLeft: "5px",
    // fontSize: "11px",
  };


  // const GetEditData = async () => {
  //   const response = await getData(`/masters/user-help/${id}`);
  //   setFormData(response?.data);
  //   // setData(response);
  //   getLanguges();
  // };
  const [langFetched, setLangFetched] = useState(false);
  const [countLang, setCountLang] = useState([]);
  const getLanguges = async () => {
    try {
      const response = await Languages();
      setCountLang(response.data);
      setLangFetched(true); 
      // setFormData((prevFormData) => ({ ...prevFormData, ...formData ,count: response?.data }));
    } catch (error) {
      console.error("Error fetching languages:", error);
    }
  };

  const GetEditData = async () => {
    try {
      const response = await getData(`/masters/rider-help/${id}`);
      if (response?.data) {
        const formattedData = response.data.count.reduce((acc, item) => {
          acc[item.language_id] = {
            question: item.question,
            answer: item.answer,
          };
          return acc;
        }, {});
        formattedData.rider_help_type_id = response.data.rider_help_type_id;
        // formattedData.user_help_sub_type_id = response.data.user_help_sub_type_id;

        const combinedData = {
          ...formattedData,
          count: countLang,
        };

        // Setting the formData state with the combined data
        setFormData(combinedData);
      }  
    } catch (error) {
      console.error("Error fetching or processing data:", error);
    }
  };

  useEffect(() => {
    getLanguges();
  }, []);

  useEffect(() => {
    if (langFetched) {
      GetEditData();
    }
  }, [langFetched]);

  useEffect(() => {
    console.log(formData, "formData formData");
  }, [formData]);
//   const GetEditData = async () => {
//     const response = await getData(`/masters/user-help/${id}`);
//     console.log(response?.data,"response?.data");
//     setFormData(response?.data);
//     getLanguges();
//   };

// console.log(formData,"formData formData");
//   useEffect(() => {
//     GetEditData();
//   }, []);
//   const [countLang, setCountLang] = useState([]);

//   const getLanguges = async () => {
//     const response = await Languages();
//     setCountLang(response.data);
//     const initialFormData = response.data.reduce((acc, lang) => {
//       acc[lang.id] = { question: "", answer: "" };
//       return acc;
//     }, {});
//     setFormData((prevFormData) => ({ ...prevFormData, ...initialFormData ,count: response?.data }));
//   };

  // useEffect(() => {
  //   getLanguges();
  // }, []);
  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton>
          {/* <Offcanvas.Title>Edit State</Offcanvas.Title> */}
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="shadow-lg p-3 mb-5 bg-white rounded">
            <Card.Body>
              <Card.Title>Edit Rider Help</Card.Title>
              <hr />
              <Container>
                <Row>
                  <Col md={12}>
                    <Container>
                      <Row className="">
                        <Col xxl={12} xl={12} md={10}>
                          <Form
                            onSubmit={handleSubmit}
                            role="form"
                            className="stateclass"
                          >
                            <Row>
                              {/* <Col
                                lg={8}
                                md={10}
                                className=" mx-auto Add-content"
                              >
                                <Row> */}
                              <Col md={6}>
                                <div className="main-form-section mt-5">
                                  <Row className="row justify-content-center mb-2">
                                    <Form.Label column sm={3}>
                                      Rider Help {" "}
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Select
                                        name="rider_help_type_id"
                                        className="input-mandatory"
                                        onChange={handleSelectChange}
                                        placeholder="Select User Help Type"
                                        value={ formData.rider_help_type_id
                                        }
                                        options={country}
                                      />
                                      <span className="error-message"></span>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>

                              {/* <Col md={6}>
                                <div className="main-form-section mt-5">
                                  <Row className="row justify-content-center mb-2">
                                    <Form.Label column sm={3}>
                                      User Help Sub Type{" "}
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Select
                                        name="user_help_sub_type_id"
                                        className="input-mandatory"
                                        onChange={handleSelectChange}
                                        placeholder="Select User Help Sub Type"
                                        value={formData.user_help_sub_type_id}
                                        options={userHelpSubType.map((stateOption) => ({
                                          value: stateOption.id,
                                          name: "user_help_sub_type_id",
                                          label: stateOption.name,
                                        }))}
                                      />
                                      <span className="error-message"></span>
                                    </Col>
                                  </Row>
                                </div>
                              </Col> */}

                              {countLang?.map((data, index) => (
                                <Col md={12} key={index}>
                                  <div className="main-form-section mt-5">
                                    <Row className="justify-content-center">
                                      <Form.Label column sm={3}>
                                        Question ({data?.name})
                                      </Form.Label>
                                      <Col sm={6}>
                                        <Form.Group>
                                          <InputGroup>
                                            <Form.Control
                                              type="text"
                                              name={`question_${data.id}`}
                                              placeholder={`Enter question in ${data?.name}`}
                                              value={formData[data.id]?.question || ""}
                                              onChange={handleChange}
                                              id="inputEmail3"
                                              className="input-mandatory"
                                            />
                                          </InputGroup>
                                          {errors[`question_${data.id}`] && (
                                            <span style={errorStyle}>
                                              {errors[`question_${data.id}`] + " in " + data.name}
                                            </span>
                                          )}
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>
                              ))}

                              {countLang?.map((data, index) => (
                                <Col md={12} key={index}>
                                  <div className="main-form-section mt-5">
                                    <Row className="justify-content-center">
                                      <Form.Label column sm={3}>
                                        Answer ({data?.name})
                                      </Form.Label>
                                      <Col sm={6}>
                                        <Form.Group>
                                          <InputGroup>
                                            <JoditEditor
                                              ref={editor}
                                              value={formData[data.id]?.answer || ""}
                                              tabIndex={2}
                                              className="custom-jodit-editor"
                                              onBlur={(newContent) =>
                                                handleDescriptionChange(data.id, newContent)
                                              }
                                            />
                                          </InputGroup>
                                          {errors[`answer_${data.id}`] && (
                                            <span style={errorStyle}>
                                              {errors[`answer_${data.id}`] + " in " + data.name}
                                            </span>
                                          )}
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>
                              ))}
                              {/* </Row> */}
                              <Row className="mt-5 pb-3">
                                <div className="d-flex justify-content-center">
                                  <Link>
                                    <CancelButton
                                      name={"cancel"}
                                      handleClose={props.handleClose}
                                    />
                                  </Link>
                                  <SaveButton
                                    name={"save"}
                                    handleSubmit={handleSubmit}
                                  />
                                </div>
                              </Row>
                              {/* </Col> */}
                            </Row>
                          </Form>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Offcanvas.Body>
      </Offcanvas>
      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
