import React, { useContext } from "react";
import { useState, useEffect } from "react";
import Bucket_Holder from "../../../Common _Components/Bucket_Holder/Bucket_Holder";
import { faCar, faList, faListCheck, faUserLarge, faUsers } from "@fortawesome/free-solid-svg-icons";
import { Context } from "../../../../utils/context";
import  './Buckets.css'
import { Link } from "react-router-dom";
function Buckets() {
  const { postData, getData } = useContext(Context);
  const [data, setData] = useState();
  const GetData = async () => {
    const response = await getData(`/masters/app-setup/dashboard/data`);
    setData(response?.data);


  };
  console.log(data, "data data");
  useEffect(() => {
    GetData();
  }, []);

  return (
    <section className="Buckets">
      <div className="row">

        <div className="col-md-3">
          <Link to='/customers/users'>
            <Bucket_Holder BucketName={" Total Customers"} Counts={data?.userCount} icon={faUsers} background={"linear-gradient(310deg, rgb(17, 113, 239), rgb(17, 205, 239))"} />
          </Link>
        </div>



        <div className="col-md-3">
          <Link to='/customers/rides'>
            <Bucket_Holder BucketName={" Total Rides"} Counts={data?.bookingCount} icon={faList} background={"linear-gradient(310deg, rgb(251, 99, 64), rgb(251, 177, 64))"} />
          </Link>
        </div>

        <div className="col-md-3">
          <Link to='/customers/rides'>
            <Bucket_Holder BucketName={" Complete Rides"} Counts={data?.completeBookingCount} icon={faListCheck} background={"linear-gradient(310deg, rgb(45, 206, 137), rgb(45, 206, 204))"} />
          </Link>
        </div>

        <div className="col-md-3">
          <Link to='/customers/riders'>
            <Bucket_Holder BucketName={" Total Riders"} Counts={data?.riderCount} icon={faUserLarge} background={"linear-gradient(310deg, rgb(245, 54, 92), rgb(245, 96, 54))"} />
          </Link>
        </div>
      </div>
    </section>
  );
}

export default Buckets;
