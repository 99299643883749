// import React from "react";
// import "./Piechart.css";
// import { Chart } from "react-google-charts";

// export const data = [
//   ["Task", "Hours per Day"],
//   ["Marketing", 2],
//   ["Sales", 4],
//   ["Human Resources", 0],
//   ["Public relations", 4],
//   ["Research", 2],
//   ["Finance", 2],
// ];

// export const options = {};

// function Piechart({ headingText }) {
//   return (
//     <>
//       <section className="Piechart">
//         <div className="heading-holder">
//           <h3>{headingText}</h3>
//         </div>
//         <Chart
//           chartType="PieChart"
//           data={data}
//           options={options}
//           width={"100%"}
//           height={"400px"}
//         />
//       </section>
//     </>
//   );
// }
// export default Piechart;

import React, { useContext } from "react";
import { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import "./Piechart.css";
import { Context } from "../../../../utils/context";
const Piechart = ({ headingText }) => {
  const { postData, getData } = useContext(Context);
  const [data, setData] = useState();
  const [series, setSeries] = useState([]);
  const GetData = async () => {
    const response = await getData(`/masters/app-setup/dashboard/data`);
    setData(response?.data);
    setSeries([response.data.cancleBookingCount, response.data.completeBookingCount]);
   
  };
console.log(data?.cancleBookingCount,"cancle data");
console.log(data?.completeBookingCount,"complete data");
  useEffect(() => {
    GetData();
  }, []);
  
  
  const [options, setOptions] = useState({
    chart: {
      width: 380,
      type: 'donut',
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'gradient',
    },
    labels: ['Cancelled Rides', 'Completed Rides'],
    legend: {
      formatter: function (val, opts) {
        return val + ' - ' + opts.w.globals.series[opts.seriesIndex];
      },
    },
    responsive: [
      {
        breakpoint: 1200,
        options: {
          chart: {
            width: 350,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  });

  return (
    <>
      <div>
        <section className="Piechart">
          <div className="heading-holder">
            <h3>{headingText}</h3>
          </div>
          <div className="chart-holder " id="chart">
            <ReactApexChart
              options={options}
              series={series}
              type="donut"
              width={380}
            />
          </div>
          <div id="html-dist"></div>
        </section>
      </div>
    </>
  );
};

export default Piechart;
