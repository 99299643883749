import React from "react";
import "./Bucket_Holder.css";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Bucket_Holder({ BucketName, Counts, background,   icon }) {
 

  const iconStyle = {
    background,
  };
  return (
    <section className="Buckets_Holder">
      <div className="text-holder">
        <h3>{BucketName}</h3>
        <h4>{Counts}</h4>
      </div>
      <div className="icon-holder" style={iconStyle}>
      <FontAwesomeIcon className="icon-img" icon={icon}  />
      </div>
    </section>
  );
}

export default Bucket_Holder;
