import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Offcanvas from "react-bootstrap/Offcanvas";

import Card from "react-bootstrap/Card";
import ModalSave from "../../../common/ModalSave";
import Validation from "../../../common/FormValidation";
import { CancelButton, SaveButton } from "../../../common/Button";

import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import { Vehicle,Rider } from "../../../../utils/apis/masters/masters";
import Select from "react-select";
library.add(fas);

const AddOffCanvance = (props) => {
  const { postData } = useContext(Context);
  const [errors, setErrors] = useState();
  const [imagePreview, setImagePreview] = useState(null);
  const [formData, setFormData] = useState({
    rider_id: "",
    vehicle_id: "",
  });
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  // const handleChange = (e) => {
  //   if (e.target.type === "file") {
  //     const selectedImage = e.target.files[0];

  //     // Check if a file is selected
  //     if (selectedImage) {
  //       // Generate image preview
  //       const reader = new FileReader();
  //       reader.onloadend = () => {
  //         setImagePreview(reader.result);
  //       };
  //       reader.readAsDataURL(selectedImage);

  //       // Update formData with the selected image
  //       setFormData({ ...formData, image: selectedImage });
  //     } else {
  //       // User canceled image selection, clear the image preview and formData
  //       setImagePreview(null);
  //       setFormData({ ...formData, image: null });
  //     }
  //   } else {
  //     // Handle text input
  //     setFormData({ ...formData, [e.target.name]: e.target.value });
  //   }
  // };

  const validateForm = () => {
    let errors = {};
    const regexNumeric = /^[0-9]+$/;
    const regexAlfaNumric = /^[A-Za-z0-9]+(\s[A-Za-z0-9]+)*$/;
    const regexAlfa = /^[A-Za-z]+(\s[A-Za-z]+)*$/;

    if (!formData.rider_id) {
      errors.rider_id = "Room name is required";
    } 
    // else if (formData.name.length > 81) {
    //   errors.name = "Title Must be 80 characters Maximum";
    // }
    // if (!formData.image) {
    //   errors.image = "Image is required";
    // }
    return errors;
  };

  const errorStyle = {
    color: "red",
    marginLeft: "5px",
    // fontSize: "11px",
  };

  const handleSubmit = async (e) => {
    console.log(e.target,"fffffffffff");
    e.preventDefault();

    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const formDataToSend = new FormData();
        // formDataToSend.append("name", formData.name);
        formDataToSend.append("rider_id", formData.rider_id?.value);
        formDataToSend.append("vehicle_id", formData.vehicle_id?.value);
        // formDataToSend.append("image", formData.image);
        console.log(formDataToSend,"formDataToSend");
        const response = await postData(
          "/masters/assign-vehicle",
          formDataToSend
        );
        if (response?.success) {
          setShowModal({ code: response?.code, message: response?.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        } else {
          setShowModal({ code: response?.code, message: response?.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const [vehicle, setvehicle] = useState([]);
  const [rider, setRider] = useState([]);
  const GetAllVehicle = async () => {
    const response = await Vehicle();

    const option = [];
    if (response.success) {
      response?.data?.map((data) => {
        option.push({
          value: data.id,
          name: "vehicle_id",
          label: data.name,
        });
      });
    }
    setvehicle(option);
  };

  const GetAllRider = async () => {
    const response = await Rider();

    const option = [];
    if (response.success) {
      response?.data?.map((data) => {
        option.push({
          value: data.id,
          name: "rider_id",
          label: data.f_name,
        });
      });
    }
    setRider(option);
  };

  useEffect(() => {
    GetAllVehicle();
    GetAllRider();
  }, []);

  const handleChange = (e) => {
    if (e?.name == "rider_id") {
      setFormData({ ...formData, [e.name]: e });
    } else if (e?.name == "vehicle_id") {
      setFormData({ ...formData, [e.name]: e });
    } else
     {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };
  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton>
          {/* <Offcanvas.Title>Add User Help Type</Offcanvas.Title> */}
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="shadow-lg p-3 mb-5 bg-white rounded ">
            <Card.Body>
              <Card.Title>Add Assign Vehicle</Card.Title>
              <hr />
              <Container>
                <Row>
                  <Col md={12}>
                    <Container>
                      <Row className="">
                        <Col xxl={12} xl={12} md={10}>
                          <Form
                            onSubmit={handleSubmit}
                            role="form"
                            className="blogfeedclass"
                          >
                            <Row>
                              <Col
                                lg={8}
                                md={10}
                                className=" mx-auto Add-content"
                              >
                                <Row>
                                <Col md={6}>
                                <div className="main-form-section mt-5">
                                  <Row className="row justify-content-center mb-2">
                                    <Form.Label column sm={3}>
                                      Vehicle{" "}
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Select
                                        name="vehicle_id"
                                        className="input-mandatory"
                                        onChange={handleChange}
                                        placeholder="Select Vehicle"
                                        value={formData.vehicle_id}
                                        options={vehicle}
                                      />
                                      <span className="error-message"></span>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="main-form-section mt-5">
                                  <Row className="row justify-content-center mb-2">
                                    <Form.Label column sm={3}>
                                      Rider{" "}
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Select
                                        name="rider_id"
                                        className="input-mandatory"
                                        onChange={handleChange}
                                        placeholder="Select Rider"
                                        value={formData.rider_id}
                                        options={rider}
                                      />
                                      <span className="error-message"></span>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                                  {/* <Col md={12}>
                                    <div className="main-form-section mt-5">
                                      <Row className="justify-content-center">
                                        <Form.Label column sm={3}>
                                          Title
                                        </Form.Label>
                                        <Col sm={6}>
                                          <Form.Group>
                                            <InputGroup>
                                              <Form.Control
                                                type="text"
                                                name="name"
                                                value={formData.name}
                                                onChange={handleChange}
                                                maxLength={50}
                                                placeholder="Title "
                                                id="inputEmail3"
                                                className="input-mandatory"
                                              />
                                            </InputGroup>
                                            {errors?.name && (
                                              <span style={errorStyle}>
                                                {errors?.name}
                                              </span>
                                            )}
                                          </Form.Group>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col>
                                  <Col md={12}>
                                    <div className="main-form-section mt-5">
                                      <Row className="justify-content-center">
                                        <Form.Label column sm={3}>
                                        Number
                                        </Form.Label>
                                        <Col sm={6}>
                                          <Form.Group>
                                            <InputGroup>
                                              <Form.Control
                                                type="text"
                                                name="number"
                                                value={formData.number}
                                                onChange={handleChange}
                                                maxLength={50}
                                                placeholder="number"
                                                id="inputEmail3"
                                                className="input-mandatory"
                                              />
                                            </InputGroup>
                                            {errors?.number && (
                                              <span style={errorStyle}>
                                                {errors?.number}
                                              </span>
                                            )}
                                          </Form.Group>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col> */}
                                </Row>

                               
                                <Row className="mt-5 pb-3">
                                  <div className="d-flex justify-content-center">
                                    <Link>
                                      <CancelButton
                                        name={"cancel"}
                                        handleClose={props.handleClose}
                                      />
                                    </Link>
                                    <SaveButton
                                      name={"save"}
                                      handleSubmit={handleSubmit}
                                    />
                                  </div>
                                </Row>
                              </Col>
                            </Row>
                          </Form>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Offcanvas.Body>
      </Offcanvas>
      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
