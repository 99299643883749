import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Offcanvas from "react-bootstrap/Offcanvas";

import Card from "react-bootstrap/Card";
import ModalSave from "../../../common/ModalSave";
import Validation from "../../../common/FormValidation";
import { CancelButton, SaveButton } from "../../../common/Button";

import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import { Languages } from "../../../../utils/apis/masters/masters";
import { Country, State } from "../../../../utils/apis/masters/masters";
import Select from "react-select";
library.add(fas);

const EditOffCanvance = (props) => {
  const { postData, getData, minLength, maxLength } = useContext(Context);

  const id = props.show;
  const [country, setCountry] = useState([]);
  const [data, setData] = useState();
  const [formData, setFormData] = useState({
    name: "",
    country_id: "",
  });

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  // const handleChange = (e) => {
  //   setFormData({ ...formData, [e.target.name]: e.target.value });
  // };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   const validationErrors = Validation.FormValidation({
  //     classname: ".countryclass",
  //   });

  //   if (validationErrors) {
  //     try {
  //       const response = await postData(`/masters/country/${id}`, formData);

  //       if (response.success) {
  //         setShowModal({ code: response.code, message: response.message });
  //         setTimeout(() => {
  //           setShowModal(0);
  //           props.handleClose();
  //         }, 1000);
  //       } else {
  //         setShowModal({ code: response.code, message: response.message });
  //         setTimeout(() => {
  //           setShowModal(0);
  //           props.handleClose();
  //         }, 1000);
  //       }
  //     } catch (error) {
  //       console.error("Error submitting form:", error);
  //     }
  //   }
  // };

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let errors = {};

    countLang.forEach((field) => {
      if (!formData[field.id]) {
        errors[field.id] = "state name is required";
      } else {
        const trimmedName = formData[field.id].trim();

        if (trimmedName.length < minLength || trimmedName.length > maxLength) {
          errors[
            field.id
          ] = `state name must be between ${minLength} and ${maxLength} characters`;
        }
      }
    });

    return errors;
  };

  // const handleChange = (e) => {
  //   setFormData({ ...formData, [e.target.name]: e.target.value });
  // };

  const handleChange = (e) => {
    if (e?.name == "country_id" || e?.name == "state_id") {
      if (e?.name == "country_id") {
        setFormData({
          ...formData,
          [e.name]: e,
          state_id: "",
        });
        getSelectedState(e.value);
      } else {
        setFormData({ ...formData, [e.name]: e });
      }
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const response = await postData(`/masters/city/${id}`, formData);
        if (response.success) {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        } else {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const GetEditData = async () => {
    const response = await getData(`/masters/city/${id}`);
    setFormData(response?.data);
    setData(response);
    getLanguges();
  };

  useEffect(() => {
    GetEditData();
  }, []);

  const [countLang, setCountLang] = useState([]);

  const getLanguges = async () => {
    const response = await Languages();
    await setCountLang(response.data);
  };

  const errorStyle = {
    color: "red",
    marginLeft: "5px",
  };
  const [state, setState] = useState([]);
  async function getSelectedState(id) {
    const response = await State(id);

    if (response.success) {
      setState(response.data);
    }
  }

  const GetAllCountry = async () => {
    const response = await Country();

    const option = [];
    if (response.success) {
      response?.data?.map((data) => {
        option.push({
          value: data.id,
          name: "country_id",
          label: data.name,
        });
      });
    }
    setCountry(option);
  };

  useEffect(() => {
    GetAllCountry();
  }, []);

  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton>
          {/* <Offcanvas.Title>Edit Country</Offcanvas.Title> */}
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="shadow-lg p-3 mb-5 bg-white rounded">
            <Card.Body>
              <Card.Title>Edit City</Card.Title>
              <hr />
              <Container>
                <Row>
                  <Col md={6}>
                    <div className="main-form-section mt-5">
                      <Row className="row justify-content-center mb-2">
                        <Form.Label column sm={3}>
                          Select Country{" "}
                        </Form.Label>
                        <Col sm={6}>
                          <Select
                            name="country_id"
                            className="input-mandatory"
                            onChange={handleChange}
                            value={formData?.country_id}
                            options={country}
                          />

                          <span className="error-message"></span>
                        </Col>
                      </Row>
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="main-form-section mt-5">
                      <Row className="row justify-content-center mb-2">
                        <Form.Label column sm={3}>
                          Select State{" "}
                        </Form.Label>
                        <Col sm={6}>
                          <Select
                            name="state_id"
                            className="input-mandatory"
                            onChange={handleChange}
                            value={formData.state_id}
                            options={state?.map((stateOption) => ({
                              value: stateOption.id,
                              name: "state_id",
                              label: stateOption.name,
                            }))}
                          />
                          <span className="error-message"></span>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  {/* <Col md={6}>
                    <div className="main-form-section mt-5">
                      <Row className="justify-content-center">
                        <Form.Label column sm={3}>
                          Fare
                        </Form.Label>
                        <Col sm={6}>
                          <Form.Group>
                            <InputGroup>
                              <Form.Control
                                type="text"
                                name="fare"
                                value={formData.fare}
                                onChange={handleChange}
                                placeholder="Fare "
                                id="inputEmail3"
                                className="input-mandatory"
                              />
                            </InputGroup>
                            <span className="error-message"></span>
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                  </Col> */}
                  <Col md={12}>
                    <Container>
                      <Row className="">
                        <Col xxl={12} xl={12} md={10}>
                          <Form
                            onSubmit={handleSubmit}
                            role="form"
                            className="countryclass"
                          >
                            {countLang?.map((data, index) => {
                              return (
                                <Row key={index}>
                                  <Col
                                    lg={8}
                                    md={10}
                                    className=" mx-auto Add-content"
                                  >
                                    <Row>
                                      <Col md={12}>
                                        <div className="main-form-section mt-5">
                                          <Row className="justify-content-center">
                                            <Form.Label column sm={3}>
                                              State Name {data?.name}
                                            </Form.Label>
                                            <Col sm={6}>
                                              <Form.Group>
                                                <InputGroup>
                                                  <Form.Control
                                                    type="text"
                                                    name={data.id}
                                                    value={formData[data.id]}
                                                    placeholder={`Enter City name in ${data?.name}`}
                                                    onChange={handleChange}
                                                    className="input-mandatory"
                                                    id="inputEmail3"
                                                  />{" "}
                                                  {errors[data.id] && (
                                                    <span style={errorStyle}>
                                                      {errors[data.id] +
                                                        " in " +
                                                        data.name}
                                                    </span>
                                                  )}
                                                  <span className="error-message"></span>
                                                </InputGroup>
                                              </Form.Group>
                                            </Col>
                                          </Row>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              );
                            })}

                            <Row className="mt-5 pb-3">
                              <div className="d-flex justify-content-center">
                                <Link>
                                  <CancelButton
                                    name={"cancel"}
                                    handleClose={props.handleClose}
                                  />
                                </Link>
                                <SaveButton
                                  name={"save"}
                                  handleSubmit={handleSubmit}
                                />
                              </div>
                            </Row>
                          </Form>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Offcanvas.Body>
      </Offcanvas>
      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};
export default EditOffCanvance;
